import { CmsMemberInfo } from '@doda/common/src/interfaces/api/cmsMemberInfo';
import { ValueOf } from 'next/constants';
import { getSuggestedJobCount } from '../getSuggestedJobCount';
import { SuggestedJob } from '../../components/SuggestedJobs';

const USED_HREF =
  '/dcfront/referredJob/saiyoprojectList/?usrclk=PC_loginMember_memberFunctionArea_kyujinOffer_plusOffer&sid=PC_ScoutBox_plusOffer';
const UNUSED_HREF =
  '/consultant/?usrclk=PC_loginMember_memberFunctionArea_kyujinOffer_plusOffer';

const STOP_REQUEST = 4;
const UNUSED = 1;

const UNUSED_STATE_MAP = {
  1: '未利用',
  3: '停止中',
} as const;

type UnusedState = ValueOf<typeof UNUSED_STATE_MAP> | undefined;

/**
 * 紹介求人の採用プロジェクト担当情報を生成して返す関数
 * @param cmsMemberInfo - 個人顧客情報
 * @returns 紹介求人の採用プロジェクト担当情報
 */
export const getSuggestedRecruitProjectJob = (cmsMemberInfo: CmsMemberInfo) => {
  const isStopRequested = cmsMemberInfo.wtsrfl === STOP_REQUEST;
  const hasBeenUsed = cmsMemberInfo.wtsrfl !== UNUSED;

  const href = hasBeenUsed ? USED_HREF : UNUSED_HREF;
  const jobCount = getSuggestedJobCount('recruitmentProjectJob', cmsMemberInfo);
  const usageStatus: UnusedState = UNUSED_STATE_MAP[cmsMemberInfo.wtsrfl];

  const recruitmentProjectJob: SuggestedJob = { href, isStopRequested };

  if (jobCount) recruitmentProjectJob.jobCount = jobCount;
  if (usageStatus) recruitmentProjectJob.usageStatus = usageStatus;

  return recruitmentProjectJob;
};
