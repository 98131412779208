import { FC } from 'react';
import classNames from 'classnames';
import styles from './index.module.scss';
import { SearchMenuLogInParts } from '../SearchMenuLogInParts';
import { createOnPreviousSearchJobClick } from './logic/createOnPreviousSearchJobClick';
import {
  DEFAULT_MATCH_JOB_LIST,
  JOB_SEARCH_TOP_URL,
  MATCH_JOB_LIST,
} from './constants';

/**
 * 検索メニュー（ログイン）コンポーネントのProps
 */
export interface SearchMenuLogInProps {
  // Cookieに[desired_cond]が存在するか
  isDesiredCond: boolean;
  // 希望条件にマッチした求人_新着ありで取得した総件数が1以上か
  hasNewArrivalMatchJob: boolean;
  // 希望条件テキスト
  desiredCondition?: string;
  // 前回の検索条件パラメータ
  searchConditionParam?: string;
}

export const SearchMenuLogIn: FC<SearchMenuLogInProps> = ({
  isDesiredCond,
  hasNewArrivalMatchJob,
  desiredCondition,
  searchConditionParam,
}) => {
  const matchJobUrl = isDesiredCond ? MATCH_JOB_LIST : DEFAULT_MATCH_JOB_LIST;
  const previousSearchJobClick =
    createOnPreviousSearchJobClick(searchConditionParam);
  return (
    <ul className={styles.searchMenuLogIn}>
      <li
        className={classNames(
          styles.searchMenuLogIn__list,
          styles['searchMenuLogIn__list--matchJob']
        )}
      >
        <SearchMenuLogInParts
          name="希望条件にマッチした求人"
          url={matchJobUrl}
          hasNewArrival={hasNewArrivalMatchJob}
          desiredCondition={desiredCondition}
          showDesiredCondition
        />
      </li>
      <li
        className={classNames(
          styles.searchMenuLogIn__list,
          styles['searchMenuLogIn__list--previousSearchJob']
        )}
      >
        <SearchMenuLogInParts
          name="前回の検索条件"
          onClick={previousSearchJobClick}
          showDesiredCondition={false}
        />
      </li>
      <li
        className={classNames(
          styles.searchMenuLogIn__list,
          styles['searchMenuLogIn__list--jobSearchTop']
        )}
      >
        <SearchMenuLogInParts
          name="詳細検索条件"
          url={JOB_SEARCH_TOP_URL}
          showDesiredCondition={false}
        />
      </li>
    </ul>
  );
};
