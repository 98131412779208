import { WorkStyleDetail, WorkStyles } from '../types';

export const HOLIDAY_WORK_STYLE: WorkStyleDetail[] = [
  {
    name: '在宅勤務',
    imagePath:
      '//src.doda.jp/cmn_web/img/workstyle/category_1_2x.jpg?v=yyyymmdd',
    searchPath: '/DodaFront/View/JobSearchList/j_k__/在宅勤務/',
    searchParam: 'kyujinSearchWorkStyleArea_workStyle_workRemotely',
  },
  {
    name: '完全週休2日制',
    imagePath:
      '//src.doda.jp/cmn_web/img/workstyle/category_2_2x.jpg?v=yyyymmdd',
    searchPath: '/DodaFront/View/JobSearchList/j_op__69/',
    searchParam: 'kyujinSearchWorkStyleArea_workStyle_fivedayWeek',
  },
  {
    name: '年間休日<br>120日以上',
    imagePath:
      '//src.doda.jp/cmn_web/img/workstyle/category_3_2x.jpg?v=yyyymmdd',
    searchPath: '/DodaFront/View/JobSearchList/j_op__5/',
    searchParam: 'kyujinSearchWorkStyleArea_workStyle_year',
  },
  {
    name: '土日祝休み',
    imagePath:
      '//src.doda.jp/cmn_web/img/workstyle/category_4_2x.jpg?v=yyyymmdd',
    searchPath: '/DodaFront/View/JobSearchList/j_op__72/',
    searchParam: 'kyujinSearchWorkStyleArea_workStyle_holiday',
  },
  {
    name: '残業20時間未満',
    imagePath:
      '//src.doda.jp/cmn_web/img/workstyle/category_5_2x.jpg?v=yyyymmdd',
    searchPath: '/DodaFront/View/JobSearchList/j_op__60/',
    searchParam: 'kyujinSearchWorkStyleArea_workStyle_overtimeWork',
  },
  {
    name: '産休・育休<br>取得実績あり',
    imagePath:
      '//src.doda.jp/cmn_web/img/workstyle/category_6_2x.jpg?v=yyyymmdd',
    searchPath: '/DodaFront/View/JobSearchList/j_op__75/',
    searchParam: 'kyujinSearchWorkStyleArea_workStyle_maternityLeave',
  },
  {
    name: '原則定時退社',
    imagePath:
      '//src.doda.jp/cmn_web/img/workstyle/category_19_2x.jpg?v=yyyymmdd',
    searchPath: '/DodaFront/View/JobSearchList/j_op__74/',
    searchParam: 'kyujinSearchWorkStyleArea_workStyle_fixedTime',
  },
  {
    name: 'フレックス勤務',
    imagePath:
      '//src.doda.jp/cmn_web/img/workstyle/category_20_2x.jpg?v=yyyymmdd',
    searchPath: '/DodaFront/View/JobSearchList/j_op__6/',
    searchParam: 'kyujinSearchWorkStyleArea_workStyle_frex',
  },
];

export const ENVIRONMENT_LANGUAGE: WorkStyleDetail[] = [
  {
    name: '上場企業',
    imagePath:
      '//src.doda.jp/cmn_web/img/workstyle/category_7_2x.jpg?v=yyyymmdd',
    searchPath: '/DodaFront/View/JobSearchList/j_op__47/',
    searchParam: 'kyujinSearchWorkStyleArea_workEnvironment_listedCompany',
  },
  {
    name: '外資系企業',
    imagePath:
      '//src.doda.jp/cmn_web/img/workstyle/category_8_2x.jpg?v=yyyymmdd',
    searchPath: '/DodaFront/View/JobSearchList/j_op__4/',
    searchParam: 'kyujinSearchWorkStyleArea_workEnvironment_alienCorporation',
  },
  {
    name: '服装自由',
    imagePath:
      '//src.doda.jp/cmn_web/img/workstyle/category_9_2x.jpg?v=yyyymmdd',
    searchPath: '/DodaFront/View/JobSearchList/j_op__32/',
    searchParam: 'kyujinSearchWorkStyleArea_workEnvironment_freeClothing',
  },
  {
    name: '女性活躍',
    imagePath:
      '//src.doda.jp/cmn_web/img/workstyle/category_10_2x.jpg?v=yyyymmdd',
    searchPath: '/DodaFront/View/JobSearchList/j_op__33/',
    searchParam: 'kyujinSearchWorkStyleArea_workEnvironment_goodShowing',
  },
  {
    name: '定着率95%以上',
    imagePath:
      '//src.doda.jp/cmn_web/img/workstyle/category_11_2x.jpg?v=yyyymmdd',
    searchPath: '/DodaFront/View/JobSearchList/j_op__77/',
    searchParam: 'kyujinSearchWorkStyleArea_workEnvironment_retentionRate',
  },
  {
    name: '自動車通勤OK',
    imagePath:
      '//src.doda.jp/cmn_web/img/workstyle/category_12_2x.jpg?v=yyyymmdd',
    searchPath: '/DodaFront/View/JobSearchList/j_op__55/',
    searchParam: 'kyujinSearchWorkStyleArea_workEnvironment_commutingByCar',
  },
  {
    name: '英語を活かす',
    imagePath:
      '//src.doda.jp/cmn_web/img/workstyle/category_21_2x.jpg?v=yyyymmdd',
    searchPath: '/DodaFront/View/JobSearchList/j_op__21/',
    searchParam: 'kyujinSearchWorkStyleArea_workEnvironment_english',
  },
  {
    name: '中国語を活かす',
    imagePath:
      '//src.doda.jp/cmn_web/img/workstyle/category_22_2x.jpg?v=yyyymmdd',
    searchPath: '/DodaFront/View/JobSearchList/j_op__49/',
    searchParam: 'kyujinSearchWorkStyleArea_workEnvironment_chinese',
  },
];

export const WELFARE: WorkStyleDetail[] = [
  {
    name: '退職金制度',
    imagePath:
      '//src.doda.jp/cmn_web/img/workstyle/category_13_2x.jpg?v=yyyymmdd',
    searchPath: '/DodaFront/View/JobSearchList/j_op__56/',
    searchParam: 'kyujinSearchWorkStyleArea_benefits_retirementBonus',
  },
  {
    name: '社宅・家賃<br>補助制度',
    imagePath:
      '//src.doda.jp/cmn_web/img/workstyle/category_14_2x.jpg?v=yyyymmdd',
    searchPath: '/DodaFront/View/JobSearchList/j_op__44/',
    searchParam: 'kyujinSearchWorkStyleArea_benefits_rentSubsidy',
  },
  {
    name: '固定給<br>25万円以上',
    imagePath:
      '//src.doda.jp/cmn_web/img/workstyle/category_15_2x.jpg?v=yyyymmdd',
    searchPath: '/DodaFront/View/JobSearchList/j_op__79/',
    searchParam: 'kyujinSearchWorkStyleArea_benefits_payoff25',
  },
  {
    name: '固定給<br>35万円以上',
    imagePath:
      '//src.doda.jp/cmn_web/img/workstyle/category_16_2x.jpg?v=yyyymmdd',
    searchPath: '/DodaFront/View/JobSearchList/j_op__80/',
    searchParam: 'kyujinSearchWorkStyleArea_benefits_payoff35',
  },
  {
    name: 'Ｕ・Ｉターン<br>支援あり',
    imagePath:
      '//src.doda.jp/cmn_web/img/workstyle/category_17_2x.jpg?v=yyyymmdd',
    searchPath: '/DodaFront/View/JobSearchList/j_op__81/',
    searchParam: 'kyujinSearchWorkStyleArea_benefits_aegis',
  },
  {
    name: '資格取得支援制度',
    imagePath:
      '//src.doda.jp/cmn_web/img/workstyle/category_18_2x.jpg?v=yyyymmdd',
    searchPath: '/DodaFront/View/JobSearchList/j_op__45/',
    searchParam: 'kyujinSearchWorkStyleArea_benefits_credential',
  },
  {
    name: '研修制度充実',
    imagePath:
      '//src.doda.jp/cmn_web/img/workstyle/category_23_2x.jpg?v=yyyymmdd',
    searchPath: '/DodaFront/View/JobSearchList/j_op__46/',
    searchParam: 'kyujinSearchWorkStyleArea_benefits_training',
  },
  {
    name: '育児・託児<br>支援制度',
    imagePath:
      '//src.doda.jp/cmn_web/img/workstyle/category_24_2x.jpg?v=yyyymmdd',
    searchPath: '/DodaFront/View/JobSearchList/j_op__65/',
    searchParam: 'kyujinSearchWorkStyleArea_benefits_childcare',
  },
];

export const WORK_STYLES: WorkStyles[] = [
  { subTitle: '休日・はたらき方', workStyles: HOLIDAY_WORK_STYLE },
  { subTitle: '会社・職場の環境／語学', workStyles: ENVIRONMENT_LANGUAGE },
  { subTitle: '待遇・福利厚生', workStyles: WELFARE },
];
