import { NullableNumber } from '../../constants';

interface ActionButton {
  // ボタン内テキスト
  text: string;
  // ボタン内URL
  href: string;
}

/**
 * アクションボタン表示関数
 * @param caseId 案件ID
 * @param scoutListId スカウトリストID
 * @param statusCheck 状況確認ステータス
 * @param recipientInformationId 受信先情報ID
 * @param messageId メッセージID
 * @returns 「求人詳細を見る」「オファー情報を見る」どちらのボタンを表示するか
 */
export const getActionButton = (
  caseId: NullableNumber,
  scoutListId: NullableNumber,
  statusCheck: NullableNumber,
  recipientInformationId: NullableNumber,
  messageId: NullableNumber
): ActionButton => {
  const jobDetailButtonType: ActionButton = statusCheck
    ? {
        text: '求人詳細を見る',
        href: `/DodaFront/View/JobSearchDetail.action?jid=${caseId}&osscfl=12&wlstid=${scoutListId}`,
      }
    : {
        text: 'オファー情報を見る',
        href: `/dcfront/referredJob/interviewOfferDetail/?wriid=${recipientInformationId}&jid=${caseId}&message_id=${messageId}`,
      };

  return jobDetailButtonType;
};
