import React, { FC, Fragment } from 'react';
import { SectionTitle, Text } from '@doda/io-react/common';
import styles from './index.module.scss';
import { DraggableSlider } from '../DraggableSlider';
import { getWorkStylesWithHref } from './logics/workStylesWithHref';

/**
 * ログインチェックパラメータ
 */
interface JobSearchByWorkStyleProps {
  isLoggedIn: boolean;
}

export const JobSearchByWorkStyle: FC<JobSearchByWorkStyleProps> = ({
  isLoggedIn,
}) => {
  const workStylesWithHref = getWorkStylesWithHref(isLoggedIn);

  return (
    <section>
      <SectionTitle
        bgType="none"
        size="XL"
        as="h2"
        className={styles.section__title}
      >
        はたらき方から探す
      </SectionTitle>
      {workStylesWithHref.map(({ subTitle, workStyles }) => (
        <Fragment key={subTitle}>
          <SectionTitle
            bgType="none"
            size="M"
            as="h3"
            className={styles.section_subtitle}
          >
            {subTitle}
          </SectionTitle>
          <div className={styles.section__block}>
            <DraggableSlider>
              {workStyles.map((workStyle) => (
                <div key={workStyle.searchPath}>
                  <a
                    href={workStyle.href}
                    target="_self"
                    className={styles['jobSearch-carousel__link']}
                  >
                    <img
                      src={workStyle.imagePath}
                      alt={workStyle.alt}
                      className={styles['jobSearch-carousel__image']}
                    />
                    <Text
                      size="14--articlePC"
                      color="white"
                      className={styles['jobSearch-carousel__name']}
                    >
                      {workStyle.name.map((nameRow, index) => (
                        <Fragment key={nameRow}>
                          {nameRow}
                          {index < workStyle.name.length - 1 && <br />}
                        </Fragment>
                      ))}
                    </Text>
                  </a>
                </div>
              ))}
            </DraggableSlider>
          </div>
        </Fragment>
      ))}
    </section>
  );
};
