import { addQueriesToUrl } from 'pages/DodaFront/View/Top/logics/addQueriesToUrl';
import { getUsrclkQuery } from 'pages/DodaFront/View/Top/logics/getUsrclkQuery';
import { WorkStyleDetail } from '../../types';
import { WORK_STYLES } from '../../constants';
/**
 * サイドバーTOPバナー表示用データ作成関数
 * @param isLoggedIn - ログイン状態
 * @returns バナー表示に必要なデータ
 */
export const getWorkStylesWithHref = (isLoggedIn: boolean) => {
  return WORK_STYLES.map(({ subTitle, workStyles }) => ({
    subTitle,
    workStyles: workStyles.map((workStyle: WorkStyleDetail) => {
      const usrclkQuery = getUsrclkQuery(isLoggedIn, workStyle.searchParam);
      const href = addQueriesToUrl(workStyle.searchPath, usrclkQuery);
      const alt = workStyle.name.replace(/<br>/g, '');
      const name = workStyle.name.split('<br>');
      return {
        href,
        alt,
        name,
        imagePath: workStyle.imagePath,
        searchPath: workStyle.searchPath,
      };
    }),
  }));
};
