import { FC } from 'react';
import { Text } from '@doda/io-react/common';
import styles from './index.module.scss';
import { getPrefix } from '../../logics/getPrefix';
import { getKnowAndAskContentLists } from './logics/getKnowAndAskContentLists';

/**
 * 知りたい・聞きたい！コンポーネントのProps
 */
interface KnowAndAskContentsProps {
  // isLoggedIn ログイン状態
  isLoggedIn: boolean;
}

export const KnowAndAskContents: FC<KnowAndAskContentsProps> = ({
  isLoggedIn,
}) => {
  const prefix = getPrefix(isLoggedIn);
  const listDetail = getKnowAndAskContentLists(prefix);

  return (
    <section>
      <div className={styles.knowAndAskContents__sectionTitleArea}>
        <Text
          color="white"
          size="20"
          as="h2"
          weight="bold"
          className={styles.knowAndAskContents__sectionTitle}
        >
          知りたい・聞きたい！
        </Text>
        <img
          src="//src.doda.jp/cmn_web/img/index/bg_other_header.png"
          alt="知りたい・聞きたい！"
          className={styles.knowAndAskContents__sectionTitleImage}
        />
      </div>
      <div className={styles.knowAndAskContents__sectionList}>
        <ul className={styles.knowAndAskContents__list}>
          {listDetail.map((item) => (
            <li key={item.href} className={styles.knowAndAskContents__listItem}>
              <a href={item.href}>
                <img src={item.image} alt={item.alt} />
                <dl className={styles.knowAndAskContents__listItemTextArea}>
                  <dt
                    className={styles.knowAndAskContents__listItemTextAreaTitle}
                  >
                    {item.title}
                  </dt>
                  <dd
                    className={styles.knowAndAskContents__listItemTextAreaText}
                  >
                    {item.text}
                  </dd>
                </dl>
              </a>
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
};
