import { useEffect, useRef } from 'react';

/**
 * refを渡した要素が画面に表示されたとき、引数のcallbackを発火させるhook。
 * 一度の呼び出しで、複数要素に対して監視が可能。
 * @param callback 要素が画面に表示されたときに発火される関数
 * @returns 監視対象に渡すref
 */
export const useIntersectionObserver = <T extends unknown[]>(
  callback: (...args: T) => void
) => {
  const observerRefs = useRef<
    { element: Element; args: T; hasIntersected: boolean }[]
  >([]);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const observedItem = observerRefs.current.find(
            (item) => item.element === entry.target
          );
          if (observedItem && !observedItem.hasIntersected) {
            observedItem.hasIntersected = true;
            callback(...observedItem.args);
          }
        }
      });
    });

    observerRefs.current.forEach(({ element }) => {
      if (element) observer.observe(element);
    });

    return () => {
      observer.disconnect();
    };
  }, [callback]);

  const setObserverRef = (element: Element | null, ...args: T) => {
    if (
      element &&
      !observerRefs.current.some((item) => item.element === element)
    ) {
      observerRefs.current.push({ element, args, hasIntersected: false });
    }
  };
  return { setObserverRef };
};
