import { useAppDispatch } from '@doda/common/src/redux/hooks';
import { useEffect } from 'react';
import {
  measureImpressionTodoListAA,
  measureTodoListClickAA,
} from '../../logics/analytics';
import type { Todo } from '../../components/TodoList';

/**
 * Todoリストのimpression計測関数を発火し、Todoカードクリック時発火の計測関数を返す
 * @param todoList 現在表示中のtodoList
 * @returns Todoカードクリック時の計測関数
 */
export const useMeasureTodoList = (todoList: Todo[]) => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(measureImpressionTodoListAA(todoList));
  }, [dispatch, todoList]);

  const measureTodoClick = (todo: Todo) => {
    dispatch(measureTodoListClickAA(todoList, todo));
  };

  return { measureTodoClick };
};

export type UseMeasureTodoList = ReturnType<typeof useMeasureTodoList>;
