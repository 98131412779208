import { FC } from 'react';
import styles from './index.module.scss';
import { Banner } from '../../types';
import { createOnAnchorClick } from '../../logics/createOnAnchorClick';

/**
 * ImageBanner の Props
 */
type Props = Banner;

export const ImageBanner: FC<Props> = ({
  href,
  image,
  rel,
  target,
  alt,
  destinationUrl,
  externalWindow,
}) => {
  const onClick = createOnAnchorClick(destinationUrl, externalWindow);

  return (
    <a
      className={styles.imageBanner__anchor}
      href={href}
      onClick={onClick}
      target={target}
      rel={rel}
    >
      <img className={styles.imageBanner__image} src={image} alt={alt} />
    </a>
  );
};
