// getRequestParamMap/index.ts

import { ValueOf } from 'next/constants';
import { QUERY_KEY_NAME } from '../../constants';

type QueryKey = ValueOf<typeof QUERY_KEY_NAME>;

/**
 * クイック検索のリクエストパラメータを作成する関数
 * @param occupation 職種
 * @param location 勤務地
 * @param income 給料
 * @param keyword キーワード
 * @param isNewArrival 新着判定
 * @returns クイック検索のリクエストパラメータ
 */
export const getRequestParamMap = (
  occupation?: string,
  location?: string,
  income?: string,
  keyword?: string,
  isNewArrival?: boolean
) => {
  const requestParamsMp = new Map<QueryKey, string>();

  if (occupation) requestParamsMp.set(QUERY_KEY_NAME.occupation, occupation);
  if (location) requestParamsMp.set(QUERY_KEY_NAME.location, location);
  if (income) requestParamsMp.set(QUERY_KEY_NAME.income, income);
  if (keyword) requestParamsMp.set(QUERY_KEY_NAME.keyword, keyword);
  if (isNewArrival) requestParamsMp.set(QUERY_KEY_NAME.isNewArrival, '1');

  const preBtn = getPreBtn(occupation, location);
  if (preBtn) requestParamsMp.set(QUERY_KEY_NAME.preBtn, preBtn);

  return requestParamsMp;
};

const getPreBtn = (occupation?: string, location?: string) => {
  if (occupation && location) return '1';
  if (occupation) return '3';
  if (location) return '1';
  return undefined;
};
