export const MATCH_JOB_LIST_QUERY_PARAM =
  '?usrclk=PC_login_kyujinSearchArea_matchJobList';

export const MATCH_JOB_LIST = `/DodaFront/View/MatchJobList.action${MATCH_JOB_LIST_QUERY_PARAM}`;

export const DEFAULT_MATCH_JOB_LIST = `/dcfront/personal/personalRequirementsRegist/${MATCH_JOB_LIST_QUERY_PARAM}`;

export const PREVIOUS_SEARCH_JOB_BASE_URL =
  '/DodaFront/View/JobSearchList.action';

export const PREVIOUS_SEARCH_JOB_BASE_COMMON_QUERIES =
  'usrclk=PC_login_kyujinSearchArea_befSearchCondition&fromPcTop=true#history';

export const JOB_SEARCH_TOP_URL =
  '/DodaFront/View/JobSearchTop.action?usrclk=PC_login_kyujinSearchArea_kyujinSearchDetail';
