import { useEffect, useRef, MutableRefObject } from 'react';
import { useCountUp } from 'react-countup';

/**
 * react-countupを用いたカウントアニメーションのhooks
 * @see https://www.npmjs.com/package/react-countup
 * @param { number } count カウントアニメーションを行いたい数値
 * @returns { MutableRefObject } countUpRef refの値
 */
export const useCountUpdate = (
  count: number
): MutableRefObject<HTMLElement | null> => {
  const countUpRef = useRef(null);
  const { update } = useCountUp({
    ref: countUpRef,
    start: 0,
    end: count,
    separator: ',',
    delay: 0,
    duration: 0.3,
  });

  useEffect(() => {
    update(count);
  });

  return countUpRef;
};
