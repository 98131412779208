import { FC } from 'react';
import { Box, Text } from '@doda/io-react/common';
import { TextLink } from '../TextLink';
import styles from './index.module.scss';

/**
 * dodaからのお知らせ情報
 */
export interface NoticesFromDodaLink {
  /** dodaからのお知らせページURL */
  url: string;
  /** dodaからのお知らせ内容 */
  text: string;
  /** a タグの target 属性 */
  target?: '_blank';
  /** a タグの rel 属性 */
  rel?: 'noopener';
}

/**
 * dodaからのお知らせコンポーネントのprops
 */
export interface NoticesFromDodaListProps {
  /** 見出し */
  heading: string;
  /** dodaからのお知らせリンク用配列 */
  noticesFromDodaLink: NoticesFromDodaLink[];
}

export const NoticesFromDoda: FC<NoticesFromDodaListProps> = ({
  heading,
  noticesFromDodaLink,
}) => {
  if (!heading || noticesFromDodaLink.length === 0) {
    return null;
  }
  return (
    <Box
      color="white"
      border="gray"
      radius="4"
      className={styles.noticesFromDoda}
    >
      <Text as="h3" weight="bold" size="12">
        {heading}
      </Text>
      <ul className={styles.noticesFromDoda__list}>
        {noticesFromDodaLink.map((link) => {
          return (
            <li key={link.url}>
              <TextLink href={link.url} target={link.target} rel={link.rel}>
                {link.text}
              </TextLink>
            </li>
          );
        })}
      </ul>
    </Box>
  );
};
