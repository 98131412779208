import { Button, HeartPlus } from '@doda/io-react/common';
import classNames from 'classnames';
import { MouseEventHandler } from 'react';
import styles from './index.module.scss';

/** SaveBookmarkButton Props*/
interface Props {
  /** ボタンがクリックされたときのイベントハンドラ */
  onClick?: MouseEventHandler<HTMLButtonElement>;
  /** ボタンのサイズ（"M"または"S"） */
  size?: 'M' | 'S';
  /** ボタンの幅を指定する文字列 */
  width?: string;
  /** ボタンが読み込み状態かどうかを示すフラグ */
  isLoading?: boolean;
}

/**
 * SaveBookmarkButton コンポーネント
 *
 * @param {Props} props - コンポーネントのプロパティ
 * @param {MouseEventHandler<HTMLButtonElement>} [props.onClick] - ボタンがクリックされたときのイベントハンドラ
 * @param {"M" | "S"} [props.size="M"] - ボタンのサイズ
 * @param {string} [props.width] - ボタンの幅
 * @param {boolean} [props.isLoading] - 読み込み中かどうかを示すフラグ
 * @returns {JSX.Element} - SaveBookmarkButtonコンポーネント
 */
export const SaveBookmarkButton = ({
  onClick,
  size = 'M',
  width,
  isLoading,
}: Props) => {
  return (
    <div
      style={{
        width,
      }}
    >
      <Button
        onClick={onClick}
        size={size}
        variant="outline_blue"
        widthPercentage={width ? 100 : undefined}
        disabled={isLoading}
        className={classNames(styles.saveBookmarkButton, {
          [styles['saveBookmarkButton--loading']]: isLoading,
        })}
      >
        <HeartPlus size={size} color={isLoading ? 'gray' : 'blue'} />
        気になる
      </Button>
    </div>
  );
};
