import {
  BookmarkParametersRequest,
  CareerAdvisorJobBookmarkParametersRequest,
} from '@doda/api-client/aspida/@types';
import { apiClient } from '@doda/api-client/util/apiClient';
import { useState } from 'react';
import { getCmsMemberInfoForBookmarkCount } from '@doda/common/src/redux/features/memberInfo/hooks/getCmsMemberInfoForBookmarkCount';
import { useAppDispatch } from '@doda/common/src/redux/hooks';

export type BookmarkProcessState = 'default' | 'loading' | 'saved';

/**
 * 求人ブックマークhookの引数
 */
interface Params {
  /** 求人id */
  jid: string;
  /** リクエストボディ */
  requestBody: BookmarkParametersRequest;
  /** 気になる登録されているか */
  isBookmarked?: boolean;
  /** jidに対応する求人がキャリアドバイザー紹介求人か */
  isCareerAdvisorJob?: boolean;
}

/**
 * 求人ブックマークhook
 * @param params 引数
 * @returns state: 気になる登録ステータス, saveBookmark: 気になる登録
 */
export const useSaveBookmark = ({
  jid,
  requestBody,
  isBookmarked,
  isCareerAdvisorJob = false,
}: Params) => {
  const [bookmarkProcessState, setBookmarkProcessState] =
    useState<BookmarkProcessState>(isBookmarked ? 'saved' : 'default');
  const dispatch = useAppDispatch();

  const saveBookmark = async () => {
    if (bookmarkProcessState !== 'default') return;

    setBookmarkProcessState('loading');
    try {
      if (isCareerAdvisorJob) {
        const careerAdvisorJobRequestBody: CareerAdvisorJobBookmarkParametersRequest =
          { arcsOrderIdList: [parseInt(jid, 10)] };
        await apiClient.api.v1.careerAdvisor.jobs.bookmark.post({
          body: careerAdvisorJobRequestBody,
        });
      } else {
        await apiClient.api.v1.job
          ._jid(jid)
          .bookmark.post({ body: requestBody });
      }

      setBookmarkProcessState('saved');
      dispatch(getCmsMemberInfoForBookmarkCount());
    } catch {
      setBookmarkProcessState('default');
    }
  };

  return { state: bookmarkProcessState, saveBookmark };
};
