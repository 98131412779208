import { appAxios } from '@doda/api-client/libs/axios/appAxios';

/**
 * ポップアップ用表示制御IDをAPIへ送信する
 *
 * @param wdisctrlid ポップアップ用表示制御ID
 */
export const postScoutPopupInformation = (wdisctrlid: number): void => {
  const form = new FormData();
  form.append(`wdisctrlid`, wdisctrlid.toString());

  appAxios({
    url: `/dcfront/scout/scoutPopupDispCtrl/retryView/`,
    method: `post`,
    data: form,
  });
};
