import { FC } from 'react';
import { ArrowRight, Text } from '@doda/io-react/common';
import styles from './index.module.scss';
import { getPrefix } from '../../logics/getPrefix';
import { getServiceList } from './logics/getServiceList';

/**
 * JobSuggestionServiceコンポーネントのプロパティを定義するインターフェース
 */
interface JobSuggestionServiceProps {
  /** ログイン状態を示すフラグ */
  isLoggedIn: boolean;
}

const SERVICE_INTRODUCTION = 'serviceIntroductionArea_serviceIntroduction';

/**
 * JobSuggestionServiceコンポーネント
 *
 * @param {JobSuggestionServiceProps} props - コンポーネントのプロパティ
 * @param {boolean} props.isLoggedIn - ログイン状態を示すフラグ
 * @returns {JSX.Element} JSX要素
 */
export const JobSuggestionService: FC<JobSuggestionServiceProps> = ({
  isLoggedIn,
}) => {
  const prefix = getPrefix(isLoggedIn);
  const listDetail = getServiceList(prefix);

  return (
    <section>
      <div className={styles.jobSuggestionService__sectionTitleArea}>
        <Text
          color="white"
          size="20"
          as="h2"
          weight="bold"
          className={styles.jobSuggestionService__sectionTitle}
        >
          求人を紹介してもらう
        </Text>
        <img
          src="//src.doda.jp/cmn_web/img/index/bg_service.png"
          alt="求人を紹介してもらう"
        />
      </div>
      <div className={styles.jobSuggestionService__sectionList}>
        <ul className={styles.JobSuggestionService__list}>
          {listDetail.map((item) => (
            <li
              className={styles.JobSuggestionService__listDetail}
              key={item.href}
            >
              <a
                href={item.href}
                className={styles.JobSuggestionService__listDetailLink}
              >
                <img src={item.image} alt={item.alt} />
                <span className={styles.JobSuggestionService__listDetailText}>
                  <Text
                    size="14--articlePC"
                    color="blue"
                    weight="bold"
                    as="h3"
                    className={styles.JobSuggestionService__listDetailTitle}
                  >
                    {item.title}
                  </Text>
                  <Text size="12" color="gray">
                    {item.text}
                  </Text>
                </span>
                <ArrowRight color="blue" size="XS" />
              </a>
            </li>
          ))}
        </ul>
      </div>
      <a
        href={`/refer/?usrclk=${prefix}${SERVICE_INTRODUCTION}`}
        className={styles.JobSuggestionService__linkText}
      >
        サービスのご紹介
        <ArrowRight color="blue" size="XS" />
      </a>
    </section>
  );
};
