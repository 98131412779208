import { FC } from 'react';
import { SectionTitle } from '@doda/io-react/common';
import styles from './index.module.scss';
import { MoveToListLink } from '../MoveToListLink';

/**
 * イベント・セミナー情報
 */
export interface EventSeminarItem {
  /** イベント・セミナー押下時の遷移先リンク */
  href: string;
  /** 外部ウィンドウで開くかどうかの設定 */
  title: string;
  /** &usrclk=として付与されるクエリパラメータ */
  target?: '_blank';
  /** イベント・セミナー内容テキスト */
  rel?: 'noopener';
}

/**
 * イベント・セミナーコンポーネントのprops
 */
export interface EventSeminarProps {
  /** 見出しテキスト */
  heading: string;
  /** イベント・セミナー情報の配列 */
  eventSeminars: EventSeminarItem[];
  /** 一覧リンク押下時のURL */
  listHref: string;
  /** 一覧リンクの表示テキスト */
  listText: string;
}

export const EventSeminar: FC<EventSeminarProps> = ({
  heading,
  eventSeminars,
  listText,
  listHref,
}) => {
  return (
    <>
      <SectionTitle
        size="M"
        bgType="none"
        as="h3"
        className={styles.eventSeminar__title}
      >
        {heading}
      </SectionTitle>
      <ul className={styles.eventSeminar__list}>
        {eventSeminars.length > 0 ? (
          eventSeminars.map((eventSeminar) => (
            <li
              key={eventSeminar.href}
              className={styles.eventSeminar__listItem}
            >
              <a
                href={eventSeminar.href}
                className={styles.eventSeminar__anchor}
                target={eventSeminar.target}
                rel={eventSeminar.rel}
              >
                {eventSeminar.title}
              </a>
            </li>
          ))
        ) : (
          <li className={styles['eventSeminar__listItem--notEvent']}>
            現在開催を予定しているイベント・セミナーはありません
          </li>
        )}
      </ul>
      <MoveToListLink href={listHref} text={listText} />
    </>
  );
};
