import { ImpressionAnalytics } from '@doda/common/src/components/Analytics/ImpressionAnalytics';
import { FC } from 'react';
import {
  measureImpressionTopAA,
  measureImpressionTopGA,
} from '../../logics/analytics';

/**
 * ページインプレッション計測props
 * @property isLoggedIn ログイン状態
 */
interface PageImpressionProps {
  isLoggedIn: boolean;
}

/**
 * ページインプレッション計測
 */
export const PageImpression: FC<PageImpressionProps> = ({ isLoggedIn }) => {
  return (
    <ImpressionAnalytics
      adobe={() => measureImpressionTopAA(isLoggedIn)}
      google={() => measureImpressionTopGA(isLoggedIn)}
    />
  );
};
