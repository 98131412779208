import { FC } from 'react';
import styles from './index.module.scss';
import { SuggestedJobCard, type UsageStatus } from '../SuggestedJobCard';

/**
 * 紹介求人の詳細情報
 */
export interface SuggestedJob {
  /** 求人件数 */
  jobCount?: string;
  /** 未利用ステータス */
  usageStatus?: UsageStatus;
  /** 停止リクエスト有無 */
  isStopRequested?: boolean;
  /** 遷移先URL */
  href: string;
}

/**
 * 紹介求人コンポネントのprops
 */
export interface SuggestedJobsProps {
  careerAdviserJob: SuggestedJob;
  recruitmentProjectJob: SuggestedJob;
  companyRecruiterJob: SuggestedJob;
  partnerAgentJob?: SuggestedJob;
}

export const SuggestedJobs: FC<SuggestedJobsProps> = ({
  companyRecruiterJob,
  recruitmentProjectJob,
  partnerAgentJob,
  careerAdviserJob,
}) => {
  return (
    <section className={styles.suggestedJobs__container}>
      <h3 className={styles.suggestedJobs__containerTitle}>紹介求人</h3>
      <ul className={styles.suggestedJobs__list}>
        <li className={styles.suggestedJobs__listItem}>
          <SuggestedJobCard
            name="キャリアアドバイザー"
            type="careerAdviser"
            jobCount={careerAdviserJob.jobCount}
            isStopRequested={careerAdviserJob.isStopRequested}
            usageStatus={careerAdviserJob.usageStatus}
            href={careerAdviserJob.href}
          />
        </li>
        <li className={styles.suggestedJobs__listItem}>
          <SuggestedJobCard
            name="採用プロジェクト担当"
            type="recruitmentProject"
            jobCount={recruitmentProjectJob.jobCount}
            isStopRequested={recruitmentProjectJob.isStopRequested}
            usageStatus={recruitmentProjectJob.usageStatus}
            href={recruitmentProjectJob.href}
          />
        </li>
        <li className={styles.suggestedJobs__listItem}>
          <SuggestedJobCard
            name="企業採用担当者"
            type="companyRecruiter"
            jobCount={companyRecruiterJob.jobCount}
            isStopRequested={companyRecruiterJob.isStopRequested}
            usageStatus={companyRecruiterJob.usageStatus}
            href={companyRecruiterJob.href}
          />
        </li>
        {partnerAgentJob && (
          <li className={styles.suggestedJobs__listItem}>
            <SuggestedJobCard
              name="パートナーエージェント"
              type="partnerAgent"
              jobCount={partnerAgentJob.jobCount}
              isStopRequested={partnerAgentJob.isStopRequested}
              usageStatus={partnerAgentJob.usageStatus}
              href={partnerAgentJob.href}
            />
          </li>
        )}
      </ul>
    </section>
  );
};
