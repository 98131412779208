import { FC } from 'react';
import { SectionTitle } from '@doda/io-react/common';
import { Banner } from '../../types';
import { ImageBanner } from '../ImageBanner';
import styles from './index.module.scss';

/**
 * 診断・書類作成ツールコンポーネントの props
 */
export interface DiagnosticDocumentToolProps {
  /** 見出しテキスト */
  title: string;
  /** バナーの配列 */
  banners: Banner[];
}

export const DiagnosticDocumentTool: FC<DiagnosticDocumentToolProps> = ({
  title,
  banners,
}) => {
  return (
    <>
      <SectionTitle
        size="M"
        bgType="none"
        as="h3"
        className={styles.DiagnosisDocumentCreatingTool__title}
      >
        {title}
      </SectionTitle>
      <ul className={styles.DiagnosisDocumentCreatingTool__banners}>
        {banners.map((banner) => (
          <li key={banner.href}>
            <ImageBanner
              href={banner.href}
              image={banner.image}
              alt={banner.alt}
              externalWindow={banner.externalWindow}
              rel={banner.rel}
              target={banner.target}
              destinationUrl={banner.destinationUrl}
            />
          </li>
        ))}
      </ul>
    </>
  );
};
