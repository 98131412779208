/** 気になるリストへのURL */
export const BOOK_MARK_URL = '/dcfront/bookmark/bookmarkList/';

/**
 * レコメンドからの一覧へを押下した際の遷移URL
 */
export const RECOMMEND_LINK_URL = {
  oubo_and_etsuran_area:
    '/kyujin/recommend/ranking2.html?usrclk=PC_logout_kyujinRecommendArea_recommendList',
  view_list_area:
    '/DodaFront/View/JobHistoryList.action?usrclk=PC_logout_kyujinRecommendArea_recommendList',
  bookmark_area:
    '/dcfront/bookmark/bookmarkList/?usrclk=PC_logout_kyujinRecommendArea_recommendList',
  new_job_list_area:
    '/DodaFront/View/NewJobList.action?usrclk=PC_logout_kyujinRecommendArea_newArrivalList',
  ranking_area:
    '/kyujin/recommend/ranking3.html?usrclk=PC_logout_kyujinRecommendArea_recommendList',
} as const;

/**
 * レコメンドパネル押下時に付与するusrclkの値
 */
export const RECOMMEND_DETAIL_USRCLK = {
  AWS_Recommend_oubo: 'PC_logout_kyujinRecommendArea_recommendDetail',
  AWS_Recommend_etsuran: 'PC_logout_kyujinRecommendArea_recommendDetail',
  view_list: 'PC_logout_kyujinRecommendArea_recommendDetail',
  bookmark_list: 'PC_logout_kyujinRecommendArea_recommendDetail',
  newjob_list: 'PC_logout_kyujinRecommendArea_newArrivalDetail',
  ranking_ocl_pf: 'PC_logout_kyujinRecommendArea_recommendDetail',
  ranking_all: 'PC_logout_kyujinRecommendArea_recommendDetail',
} as const;
