import { getUsrclkQuery } from '../../../../logics/getUsrclkQuery';
import { addQueriesToUrl } from '../../../../logics/addQueriesToUrl';
import { SpecialistSiteLink } from '../../types';

/**
 * 専門サイトのリンクリストの各urlにユーザークリック計測クエリパラメータを付与して返す
 * @param specialSiteLinkList 専門サイトのリンクリスト
 * @param isLoggedIn ログイン状態
 * @returns ユーザークリック計測クエリパラメータが付与された専門サイトのリンクリスト
 */
export const formatSpecialLinkList = (
  specialSiteLinkList: SpecialistSiteLink[],
  isLoggedIn: boolean
): SpecialistSiteLink[] =>
  specialSiteLinkList.map((item) => {
    const userClickQueryParam = getUsrclkQuery(isLoggedIn, item.queryParam);
    const urlWithParam = addQueriesToUrl(item.url, userClickQueryParam);
    return { ...item, url: urlWithParam };
  });
