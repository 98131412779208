import { FC } from 'react';
import { OutlineTag, SectionTitle } from '@doda/io-react/common';
import { MoveToListLink } from '../MoveToListLink';
import styles from './index.module.scss';

/**
 * 人気の求人特集の詳細
 */
export interface JobFeature {
  /** 求人押下時の遷移先リンク */
  href: string;
  /** a タグの target 属性 */
  target?: '_blank';
  /** a タグの rel 属性 */
  rel?: 'noopener';
  /** 表示される画像URL */
  image: string;
  /** 画像が表示できないときの代わりに表示される文言 */
  alt: string;
  /** 求人文言上に表示されるタグテキスト */
  tagText: string;
  /** 求人内容テキスト */
  title: string;
}

/**
 * 人気の求人特集コンポネントのprops
 */
export interface PopularJobsFeatureProps {
  /** 求人押下時の遷移先リンク */
  heading: string;
  /** 求人押下時の遷移先リンク */
  jobFeatures: JobFeature[];
  /** 求人押下時の遷移先リンク */
  listText: string;
  /** 求人押下時の遷移先リンク */
  listHref: string;
}

export const PopularJobsFeature: FC<PopularJobsFeatureProps> = ({
  heading,
  jobFeatures,
  listHref,
  listText,
}) => {
  return (
    <>
      <SectionTitle
        size="M"
        bgType="none"
        as="h3"
        className={styles.popularJobsFeature__title}
      >
        {heading}
      </SectionTitle>
      <ul className={styles.popularJobsFeature__list}>
        {jobFeatures.map((jobFeature) => (
          <li
            key={jobFeature.href}
            className={styles.popularJobsFeature__listItem}
          >
            <a
              href={jobFeature.href}
              target={jobFeature.target}
              rel={jobFeature.rel}
              className={styles.popularJobsFeature__anchor}
            >
              <img
                src={jobFeature.image}
                alt={jobFeature.alt}
                className={styles.popularJobsFeature__image}
              />
              <div>
                <OutlineTag color="blue">{jobFeature.tagText}</OutlineTag>
                <div className={styles.popularJobsFeature__cardTitle}>
                  {jobFeature.title}
                </div>
              </div>
            </a>
          </li>
        ))}
      </ul>
      <MoveToListLink href={listHref} text={listText} />
    </>
  );
};
