import { FC } from 'react';
import { RoundedTag, ArrowRight, Text } from '@doda/io-react/common';
import classNames from 'classnames';
import styles from './index.module.scss';

/**
 * 検索メニュー項目詳細
 */
interface SearchMenuLogInPartsProps {
  // 項目名
  name: string;
  // 遷移先URL
  url?: string;
  // 「新着あり」タグの表示有無
  hasNewArrival?: boolean;
  // 希望条件テキスト
  desiredCondition?: string;
  // 希望条件テキスト表示有無
  showDesiredCondition: boolean;
  // 前回の検索条件のイベントハンドラー
  onClick?: React.MouseEventHandler<HTMLElement>;
}

export const SearchMenuLogInParts: FC<SearchMenuLogInPartsProps> = ({
  name,
  url,
  hasNewArrival,
  desiredCondition,
  showDesiredCondition,
  onClick,
}) => {
  const textAreaStyle = hasNewArrival ? `withTag` : `withoutTag`;

  return (
    <a
      href={url}
      onClick={onClick}
      className={styles.searchMenuLogInParts__anchorLink}
    >
      <div
        className={classNames(
          styles.searchMenuLogInParts__listTextArea,
          styles[`searchMenuLogInParts__listTextArea--${textAreaStyle}`]
        )}
      >
        <Text
          color="blue"
          weight="bold"
          size="12"
          className={styles.searchMenuLogInParts__listTitle}
        >
          {name}
        </Text>
        {showDesiredCondition && (
          <Text
            color="gray"
            size="11"
            className={styles.searchMenuLogInParts__listText}
          >
            {desiredCondition || '希望条件を設定してください'}
          </Text>
        )}
      </div>
      {hasNewArrival && <RoundedTag color="yellow">新着あり</RoundedTag>}
      <ArrowRight size="XS" color="gray" />
    </a>
  );
};
