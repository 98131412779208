const KONW_HOW = 'contentsArea_konwhow';
const EPISODE = 'contentsArea_episode';
const QA = 'contentsArea_qa';

/**
 * @param {string} prefix - ログイン状態に応じた prefix
 * @returns 各コンテンツ詳細
 */
export const getKnowAndAskContentLists = (prefix: string) => {
  return [
    {
      title: '転職ノウハウ',
      text: '転職のはじめ方から、求人の探し方や職務経歴書・面接・退職までのノウハウを解説',
      href: `/guide/?usrclk=${prefix}${KONW_HOW}`,
      image: '//src.doda.jp/cmn_web/img/index/icon_other_01.png',
      alt: '転職ノウハウ',
    },
    {
      title: 'わたしの転職エピソード',
      text: '転職活動当時の悩み・モヤモヤをエピソードでご紹介。完全匿名だから話せるリアルトーク',
      href: `/guide/episodes/?usrclk=${prefix}${EPISODE}`,
      image: '//src.doda.jp/cmn_web/img/index/icon_other_02.png',
      alt: '転職体験談',
    },
    {
      title: '転職Q＆A',
      text: 'あなたの転職にまつわる疑問や悩みにdodaの専門スタッフやその道のプロ、転職経験者などがお答えします',
      href: `/guide/qa/?usrclk=${prefix}${QA}`,
      image: '//src.doda.jp/cmn_web/img/index/icon_other_03.png',
      alt: '転職Q＆A',
    },
  ];
};
