import { SpecialistSiteLink } from '../types';

/**
 * 専門サイトリンクのクエリパラメータ共通の接頭辞
 */
const SPECIALIST_SITE_LINK_QUERY_PARAM_PREFIX = 'specialSiteArea_siteDetail_';

/**
 * 専門サイトリンク一覧
 */
export const SPECIALIST_SITE_LINK_LIST: SpecialistSiteLink[] = [
  {
    id: '1',
    jobType: 'Web・ITエンジニア',
    jobTypeExamples: 'ITエンジニア、社内SE、Webクリエイターほか',
    url: '/engineer/',
    queryParam: `${SPECIALIST_SITE_LINK_QUERY_PARAM_PREFIX}engineer`,
  },
  {
    id: '2',
    jobType: 'モノづくりエンジニア',
    jobTypeExamples: '回路設計・機械設計、研究職、施工管理ほか',
    url: '/engineer/mono/',
    queryParam: `${SPECIALIST_SITE_LINK_QUERY_PARAM_PREFIX}engineer-mono`,
  },
  {
    id: '3',
    jobType: 'メディカル業界',
    jobTypeExamples: 'MR、CRA、医療機器営業ほか',
    url: '/medical/',
    queryParam: `${SPECIALIST_SITE_LINK_QUERY_PARAM_PREFIX}medical`,
  },
  {
    id: '4',
    jobType: '営業職・販売サービス職',
    jobTypeExamples: '営業職、販売・サービス職、SCほか',
    url: '/sales/',
    queryParam: `${SPECIALIST_SITE_LINK_QUERY_PARAM_PREFIX}sales`,
  },
  {
    id: '5',
    jobType: '金融業界',
    jobTypeExamples: '銀行法人営業、ファンドマネージャーほか',
    url: '/finance/',
    queryParam: `${SPECIALIST_SITE_LINK_QUERY_PARAM_PREFIX}finance`,
  },
  {
    id: '6',
    jobType: '企画職・管理部門',
    jobTypeExamples: '経理、人事、企画、マーケティング広報ほか',
    url: '/specialist/',
    queryParam: `${SPECIALIST_SITE_LINK_QUERY_PARAM_PREFIX}specialist`,
  },
  {
    id: '7',
    jobType: '管理職・ハイクラス',
    jobTypeExamples: '経営企画、ゼネラル・マネジャー、CxOほか',
    url: '/executive/',
    queryParam: `${SPECIALIST_SITE_LINK_QUERY_PARAM_PREFIX}executive`,
  },
  {
    id: '8',
    jobType: '海外勤務・アジア転職',
    jobTypeExamples: '海外勤務、アジアで働く、語学スキルを活かすほか',
    url: '/global/',
    queryParam: `${SPECIALIST_SITE_LINK_QUERY_PARAM_PREFIX}global`,
  },
  {
    id: '9',
    jobType: '女性の転職',
    jobTypeExamples: 'じぶんらしいキャリアを真剣に考えたい女性へ',
    url: '/woman/',
    queryParam: `${SPECIALIST_SITE_LINK_QUERY_PARAM_PREFIX}woman`,
  },
];
