/**
 * @param {string} prefix - ログイン状態に応じた prefix
 * @returns 各サービス詳細
 */

const AGENT = 'serviceIntroductionArea_agent';
const SCOUT = 'serviceIntroductionArea_scout';

export const getServiceList = (prefix: string) => {
  return [
    {
      title: 'エージェントサービス',
      text: 'dodaの専門スタッフが、非公開求人を含む約10万件の求人からあなたにぴったりの求人をご紹介します',
      href: `/consultant/?usrclk=${prefix}${AGENT}`,
      image: '//src.doda.jp/cmn_web/img/index/icon_service_01.png',
      alt: 'エージェントサービス',
    },
    {
      title: 'スカウトサービス',
      text: 'あなたの経験・スキルに興味を持った企業から直接オファーが届きます',
      href: `/scout/?usrclk=${prefix}${SCOUT}`,
      image: '//src.doda.jp/cmn_web/img/index/icon_service_02.png',
      alt: 'スカウトサービス',
    },
  ];
};
