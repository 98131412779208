import { FC } from 'react';
import { SectionTitle } from '@doda/io-react/common';
import { Banner } from '../../types';
import { ImageBanner } from '../ImageBanner';
import styles from './index.module.scss';

/**
 * 人気のサービスコンポーネントの props
 */
export interface PopularServicesProps {
  /** 見出しテキスト */
  title: string;
  /** バナーの配列 */
  banners: Banner[];
}

export const PopularServices: FC<PopularServicesProps> = ({
  title,
  banners,
}) => {
  return (
    <>
      <SectionTitle
        size="M"
        bgType="none"
        as="h3"
        className={styles.popularServices__title}
      >
        {title}
      </SectionTitle>
      <ul className={styles.popularServices__banners}>
        {banners.map((banner) => (
          <li key={banner.href}>
            <ImageBanner
              href={banner.href}
              image={banner.image}
              alt={banner.alt}
              externalWindow={banner.externalWindow}
              destinationUrl={banner.destinationUrl}
              target={banner.target}
              rel={banner.rel}
            />
          </li>
        ))}
      </ul>
    </>
  );
};
