import { FC } from 'react';
import { Box, Text } from '@doda/io-react/common';
import { TextLink } from '../TextLink';
import styles from './index.module.scss';

/**
 * PR
 */
export interface PRLink {
  /** PR内ページURL */
  href: string;
  /** PR内お知らせ内容 */
  text: string;
  /** a タグの target 属性 */
  target?: '_blank';
  /** a タグの rel 属性 */
  rel?: 'noopener';
}

/**
 * PRコンポーネントのProps
 */
export interface PRProps {
  /** 見出し */
  heading: string;
  /** PR内リンク用配列 */
  prLinkList: PRLink[];
}

export const PR: FC<PRProps> = ({ heading, prLinkList }) => {
  return (
    <Box color="white" border="gray" radius="4" className={styles.pr}>
      <Text as="h3" weight="bold" size="12">
        {heading}
      </Text>
      <ul className={styles.pr__list}>
        {prLinkList.map((link) => {
          return (
            <li key={link.href}>
              <TextLink href={link.href} target={link.target} rel={link.rel}>
                {link.text}
              </TextLink>
            </li>
          );
        })}
      </ul>
    </Box>
  );
};
