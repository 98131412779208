/** 気になるリストへのURL */
export const BOOK_MARK_URL = '/dcfront/bookmark/bookmarkList/';

/**
 * レコメンドパネル押下時に付与するusrclkの値の共通パラメータ
 */
const LOGIN_KYUJINRECOMMENDAREA_RECOMMENDLIST =
  'PC_login_kyujinRecommendArea_recommendList';

/**
 * パーソナライズエリアからの一覧へを押下した際の遷移URL
 */
export const PERSONALIZED_RECOMMEND_LINK_URL = {
  AWS_Recommend_oubo: `/kyujin/recommend/ranking2.html?usrclk=${LOGIN_KYUJINRECOMMENDAREA_RECOMMENDLIST}`,
  AWS_Recommend_etsuran: `/kyujin/recommend/ranking2.html?usrclk=${LOGIN_KYUJINRECOMMENDAREA_RECOMMENDLIST}`,
  view_list: `/DodaFront/View/JobHistoryList.action?usrclk=${LOGIN_KYUJINRECOMMENDAREA_RECOMMENDLIST}`,
  ranking_ocl: `/kyujin/recommend/ranking1.html?usrclk=${LOGIN_KYUJINRECOMMENDAREA_RECOMMENDLIST}`,
  ranking_all: `/kyujin/recommend/ranking3.html?usrclk=${LOGIN_KYUJINRECOMMENDAREA_RECOMMENDLIST}`,
  AWS_Recommend_etsuran_SINE: `/kyujin/recommend/ranking2.html?usrclk=${LOGIN_KYUJINRECOMMENDAREA_RECOMMENDLIST}`,
  AWS_Recommend_skilltag: `/kyujin/recommend/skilltag.html?usrclk=${LOGIN_KYUJINRECOMMENDAREA_RECOMMENDLIST}`,
  bookmark_list: `/dcfront/bookmark/bookmarkList/?usrclk=${LOGIN_KYUJINRECOMMENDAREA_RECOMMENDLIST}`,
  AWS_recommend: `/DodaFront/View/IntentionJobList.action?usrclk=${LOGIN_KYUJINRECOMMENDAREA_RECOMMENDLIST}`,
  WelcomeMatch: `/dcfront/bookmark/welcomeMatchList/?usrclk=${LOGIN_KYUJINRECOMMENDAREA_RECOMMENDLIST}`,
  newjob_list:
    '/DodaFront/View/NewJobList.action?usrclk=PC_login_kyujinRecommendArea_newArrivalList',
  ng_recommend: `/kyujin/recommend/ngrecommend.html?usrclk=${LOGIN_KYUJINRECOMMENDAREA_RECOMMENDLIST}`,
} as const;

/**
 * レコメンドからの一覧へを押下した際の遷移URL
 */
export const RECOMMEND_LINK_URL = {
  skill_area: `/kyujin/recommend/skilltag.html?usrclk=${LOGIN_KYUJINRECOMMENDAREA_RECOMMENDLIST}`,
  bookmark_area: `/dcfront/bookmark/bookmarkList/?usrclk=${LOGIN_KYUJINRECOMMENDAREA_RECOMMENDLIST}`,
  aws_area: `/DodaFront/View/IntentionJobList.action?usrclk=${LOGIN_KYUJINRECOMMENDAREA_RECOMMENDLIST}`,
  welcome_area: `/dcfront/bookmark/welcomeMatchList/?usrclk=${LOGIN_KYUJINRECOMMENDAREA_RECOMMENDLIST}`,
  new_job_list_area:
    '/DodaFront/View/NewJobList.action?usrclk=PC_login_kyujinRecommendArea_newArrivalList',
  ng_area: `/kyujin/recommend/ngrecommend.html?usrclk=${LOGIN_KYUJINRECOMMENDAREA_RECOMMENDLIST}`,
} as const;

export const LOGIN_PREFIX = 'PC_login_kyujin';

/**
 * レコメンドパネル押下時に付与するusrclkの値の共通パラメータ
 */
const LOGIN_KYUJINRECOMMENDAREA_RECOMMENDDETAIL =
  'PC_login_kyujinRecommendArea_recommendDetail';

/**
 * レコメンドパネル押下時に付与するusrclkの値
 */
export const RECOMMEND_DETAIL_USRCLK = {
  AWS_Recommend_oubo: LOGIN_KYUJINRECOMMENDAREA_RECOMMENDDETAIL,
  AWS_Recommend_etsuran: LOGIN_KYUJINRECOMMENDAREA_RECOMMENDDETAIL,
  view_list: LOGIN_KYUJINRECOMMENDAREA_RECOMMENDDETAIL,
  ranking_ocl: LOGIN_KYUJINRECOMMENDAREA_RECOMMENDDETAIL,
  ranking_all: LOGIN_KYUJINRECOMMENDAREA_RECOMMENDDETAIL,
  AWS_Recommend_etsuran_SINE: LOGIN_KYUJINRECOMMENDAREA_RECOMMENDDETAIL,
  AWS_Recommend_skilltag: LOGIN_KYUJINRECOMMENDAREA_RECOMMENDDETAIL,
  bookmark_list: LOGIN_KYUJINRECOMMENDAREA_RECOMMENDDETAIL,
  AWS_recommend: LOGIN_KYUJINRECOMMENDAREA_RECOMMENDDETAIL,
  WelcomeMatch: LOGIN_KYUJINRECOMMENDAREA_RECOMMENDDETAIL,
  newjob_list: 'PC_login_kyujinRecommendArea_newArrivalDetail',
  ng_recommend: LOGIN_KYUJINRECOMMENDAREA_RECOMMENDDETAIL,
} as const;
