import {
  AreaResponse,
  IndustryLResponse,
  JobTypeLResponse,
} from '@doda/api-client/aspida/@types';
import { createSlice } from '@reduxjs/toolkit';
import {
  AreaListOfSearchModal,
  CityOfSearchModal,
  CompanyOfSearchModal,
} from '../../../models/MasterCacheModel';
import { RootState } from '../../store';

/**
 * 初期型
 */
export interface MasterState {
  occupation: JobTypeLResponse[] | null;
  area: AreaListOfSearchModal[] | null;
  city: CityOfSearchModal[] | null;
  station: CompanyOfSearchModal[] | null;
  industry: IndustryLResponse[] | null;
  /** 加工前のエリアマスタ */
  rawAreaData: AreaResponse[] | null;
}

/**
 * State型
 */
export interface MasterSliceState {
  master: ReturnType<typeof masterReducer>;
}

// 初期状態
export const masterInitialState: MasterState = {
  occupation: null,
  area: null,
  city: null,
  station: null,
  industry: null,
  rawAreaData: null,
};

/**
 * マスタデータ Slice
 * @remarks マスタデータ
 */
export const masterSlice = createSlice({
  name: 'master',
  initialState: masterInitialState,
  reducers: {
    setOccupation: (state, action) => {
      state.occupation = action.payload;
    },
    setArea: (state, action) => {
      state.area = action.payload;
    },
    setCity: (state, action) => {
      state.city = action.payload;
    },
    setStation: (state, action) => {
      state.station = action.payload;
    },
    setIndustry: (state, action) => {
      state.industry = action.payload;
    },
    setRawArea: (state, action) => {
      state.rawAreaData = action.payload;
    },
  },
});

// Create Action
export const {
  setOccupation,
  setArea,
  setCity,
  setIndustry,
  setStation,
  setRawArea,
} = masterSlice.actions;

// reducer
export const masterReducer = masterSlice.reducer;

// selector
/**
 * masterSelector
 * @param state
 * @returns 全マスタデータ
 */
export const masterSelector = (state: RootState) => state.master;

const EMPTY_ARRAY = [];

/**
 * areaMasterSelector
 * @param state
 * @returns エリアマスタ
 */
export const areaMasterSelector = (state): AreaListOfSearchModal[] =>
  state.master.area || EMPTY_ARRAY;

/**
 * occupationMasterSelector
 * @param state
 * @returns 職種マスタ
 */
export const occupationMasterSelector = (state): JobTypeLResponse[] =>
  state.master.occupation || EMPTY_ARRAY;
