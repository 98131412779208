import { FC } from 'react';
import styles from './index.module.scss';
import { SearchMenuLogOutParts } from '../SearchMenuLogOutParts';
import { JOB_HISTORY_LIST, CONDITION_LIST } from './constants';
import { useMachJob } from './hooks/useMachJob';
import { createOnPreviousSearchJobClick } from './logic/createOnPreviousSearchJobClick';
import { getMachJob } from './logic/getMachJob';

/**
 * 検索メニュー（ログアウト）コンポーネントのProps
 */
export interface SearchMenuLogOutProps {
  // Cookieに[HISTORYID]が存在するか
  isHistoryId: boolean;
  // [desired_cond]の area1,income,job1 全てに "skip" が設定されているか
  isDesiredCond: boolean;
  // Cookieに[desired_cond]が存在するか
  isDesiredCondCookie: boolean;
  // 希望条件にマッチした求人_新着ありで取得した総件数が1以上か
  hasNewArrivalMatchJob: boolean;
  // 希望条件にマッチした求人数
  matchJobCount: number;
  // 希望条件テキスト
  desiredCondition?: string;
  // 前回の検索条件_新着ありで取得した総件数が1以上か
  hasNewArrivalSearchCondition: boolean;
  // 前回の検索条件にヒットした求人数
  previousSearchConditionCount: number;
  // 前回の検索条件パラメータ
  searchConditionParam?: string;
}

export const SearchMenuLogOut: FC<SearchMenuLogOutProps> = ({
  isHistoryId,
  isDesiredCond,
  isDesiredCondCookie,
  hasNewArrivalMatchJob,
  matchJobCount,
  desiredCondition,
  hasNewArrivalSearchCondition,
  previousSearchConditionCount: _previousSearchConditionCount,
  searchConditionParam,
}) => {
  const { matchJobClick } = useMachJob(isDesiredCond);
  const { matchJobUrl, jobCount } = getMachJob(
    isDesiredCond,
    isDesiredCondCookie,
    matchJobCount,
    hasNewArrivalMatchJob
  );
  const previousSearchJobClick = createOnPreviousSearchJobClick(
    hasNewArrivalSearchCondition,
    searchConditionParam
  );
  const previousSearchConditionCount = searchConditionParam
    ? _previousSearchConditionCount
    : undefined;

  if (!isHistoryId) return null;
  return (
    <ul className={styles.searchMenu}>
      <li className={styles.searchMenu__list}>
        <SearchMenuLogOutParts
          name="希望条件にマッチした求人"
          url={matchJobUrl}
          jobCount={jobCount}
          hasNewArrival={hasNewArrivalMatchJob}
          desiredCondition={desiredCondition}
          onClick={matchJobClick}
          showDesiredCondition
        />
      </li>
      <li className={styles.searchMenu__list}>
        <SearchMenuLogOutParts
          name="あなたの閲覧履歴からのおすすめ"
          url={JOB_HISTORY_LIST}
        />
      </li>
      <li className={styles.searchMenu__list}>
        <SearchMenuLogOutParts
          name="前回の検索条件"
          onClick={previousSearchJobClick}
          jobCount={previousSearchConditionCount}
          hasNewArrival={hasNewArrivalSearchCondition}
        />
      </li>
      <li className={styles.searchMenu__list}>
        <SearchMenuLogOutParts name="保存した検索条件" url={CONDITION_LIST} />
      </li>
    </ul>
  );
};
