import { useEffect, useState } from 'react';

/**
 *inputに素早く大量の入力があった際に大量のリクエストを飛ばさないように時間を調整するじ値をセットする
 * @param value 遅延対象となる値
 * @param delay 遅延時間（ミリ秒）
 * @returns 遅延処理された後の値
 */
export const useDebounce = (value: string, delay: number) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(timer);
    };
  }, [value, delay]);

  return debouncedValue;
};
