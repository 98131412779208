import { FC } from 'react';
import styles from './index.module.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { type Todo, TodoList } from '../TodoList';
import { useMeasureTodoList } from '../../hooks/useMeasureTodoList';

/**
 * ToDoリストUIコンポネントのProps
 */
interface Props {
  todoList: Todo[];
  isLoading: boolean;
}

export const ToDoListArea: FC<Props> = ({ todoList, isLoading }) => {
  const { measureTodoClick } = useMeasureTodoList(todoList);
  const hasNoTodo = !isLoading && !todoList.length;

  return (
    <section className={styles.toDoListArea__container}>
      <h3 className={styles.toDoListArea__title}>ToDoリスト</h3>
      {isLoading && <div className="toDoListArea__loading" />}
      {hasNoTodo ? (
        <p className={styles.toDoListArea__noTodoText}>ToDoはありません</p>
      ) : (
        <TodoList todoList={todoList} measureTodoClick={measureTodoClick} />
      )}
    </section>
  );
};
