/**
 * %sを前から順に置換する
 * @param {string} baseStr
 * @param {string} replaceList
 * @returns 置換後文字列
 */
export const formatString = (baseStr: string, replaceList: string[]) => {
  replaceList.forEach((i) => {
    baseStr = baseStr.replace('%s', i);
  });
  return baseStr;
};

/**
 * 半角数字判定
 * @param {string} str
 * @returns boolean
 */
export const isHalfNum = (str: string) => {
  // 半角数字の場合true
  return /^[0-9]+$/.test(str);
};

/**
 * １桁の場合２桁にする
 * @param {string | number} index 変換文字
 * @returns {string}
 */
export function convertDoubleDigit(index: string | number) {
  const number = Number(index);
  if (number > -1) {
    return number < 10 ? `0${number}` : `${number}`;
  }
  return '00';
}
