import { FC } from 'react';
import classNames from 'classnames';
import { ArrowRight, RoundedTag } from '@doda/io-react/common';
import styles from './index.module.scss';

export type SuggestedJobType =
  | 'careerAdviser'
  | 'recruitmentProject'
  | 'companyRecruiter'
  | 'partnerAgent';

export type UsageStatus = '未利用' | '停止中';

/**
 * 紹介求人カードコンポネントのprops
 */
interface SuggestedJobCardProps {
  /** 見出し */
  name: string;
  /** アイコンタイプ */
  type: SuggestedJobType;
  /** 求人件数 */
  jobCount?: string;
  /** 未利用ステータス */
  usageStatus?: UsageStatus;
  /** 停止リクエスト有無 */
  isStopRequested?: boolean;
  /** 遷移先URL */
  href: string;
}

export const SuggestedJobCard: FC<SuggestedJobCardProps> = ({
  name,
  type,
  jobCount,
  isStopRequested,
  usageStatus,
  href,
}) => {
  return (
    <a href={href} className={styles.suggestedJobCard__container}>
      <div
        className={classNames(
          styles.suggestedJobCard__icon,
          styles[`suggestedJobCard__icon--${type}`]
        )}
      />
      <div className={styles.suggestedJobCard__textArea}>
        <div className={styles.suggestedJobCard__titleArea}>
          <h4 className={styles.suggestedJobCard__title}>{name}</h4>
          {jobCount && (
            <RoundedTag
              color="yellow"
              className={styles.suggestedJobCard__tagText}
            >
              {jobCount}
            </RoundedTag>
          )}
          {usageStatus && <RoundedTag color="green">{usageStatus}</RoundedTag>}
        </div>
        {isStopRequested && (
          <p className={styles.suggestedJobCard__stopRequestText}>
            停止リクエスト中
          </p>
        )}
      </div>
      <ArrowRight size="XS" color="gray" />
    </a>
  );
};
