import { FC } from 'react';
import { Box } from '@doda/io-react/common';
import styles from './index.module.scss';
import { PASSWORD_CHANGE_NOTIFICATION_HEADER_LINK } from './constants';

/**
 * パスワード変更通知ヘッダーProps
 */
export interface PasswordChangeNotificationHeaderProps {
  /** パスワード変更通知ヘッダーを表示するかしないかのフラグ */
  isShowPasswordNotification: boolean;
}

export const PasswordChangeNotificationHeader: FC<
  PasswordChangeNotificationHeaderProps
> = ({ isShowPasswordNotification }) => {
  if (!isShowPasswordNotification) return null;

  return (
    <Box
      color="white"
      radius="4"
      border="gray"
      className={styles.passwordChangeNotificationHeader}
    >
      <p>{PASSWORD_CHANGE_NOTIFICATION_HEADER_LINK.text}</p>
      <a
        href={PASSWORD_CHANGE_NOTIFICATION_HEADER_LINK.href}
        className={styles.passwordChangeNotificationHeader__anchor}
      >
        {PASSWORD_CHANGE_NOTIFICATION_HEADER_LINK.linkText}
      </a>
    </Box>
  );
};
