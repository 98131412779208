import { FC } from 'react';
import { Box, Button } from '@doda/io-react/common';

import styles from './index.module.scss';

const SIGNUP_LINK =
  '/Front/Member/View/MemberRegist.aspx?entry_id=26&carry_id=3000000001&carry_class=k&usrclk=PC_logout_memberRegistArea_memberRegist';
const LOGIN_LINK =
  '/dcfront/login/login/?usrclk=PC_logout_memberRegistArea_login&url=/index.html';
const INTRO_LINK = '/intro/?usrclk=PC_logout_memberRegistArea_intro';

/**
 * 会員登録/ログインコンポーネントの props
 */
interface Props {
  /** (非公開含む)全求人件数 */
  totalCountWithPrivateJobs: number;
}

export const SignupLoginArea: FC<Props> = ({ totalCountWithPrivateJobs }) => {
  return (
    <Box
      color="white"
      radius="4"
      boxShadow="black"
      className={styles.signupLoginArea__container}
    >
      <div className={styles.signupLoginArea__jobCountsText}>
        dodaの求人{totalCountWithPrivateJobs.toLocaleString()}
        件(非公開求人を含む)
      </div>
      <Button as="a" href={SIGNUP_LINK} size="M" variant="orange">
        会員登録する
        <span className={styles.signupLoginArea__freeText}>（無料）</span>
      </Button>
      <div className={styles.signupLoginArea__login}>
        ログインは
        <a href={LOGIN_LINK} className={styles['signupLoginArea__login--link']}>
          こちら
        </a>
      </div>
      <Button
        as="a"
        href={INTRO_LINK}
        size="M"
        variant="outline_gray"
        className={styles.signupLoginArea__introButton}
      >
        はじめてdodaをご利用される方へ
      </Button>
    </Box>
  );
};
