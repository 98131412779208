import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import {
  areaMasterSelector,
  occupationMasterSelector,
} from '@doda/common/src/redux/features/master';
import { useIsomorphicLayoutEffect } from '@doda/common/src/hooks/useIsomorphicLayoutEffect';
import { MatchJobDialog } from 'components/MatchJobDialog';
import { useModalState, useSetModal } from 'pages/index.page';

export const DesiredCondModal = () => {
  const dialogRef = useRef<HTMLDialogElement>(null);
  const areaMaster = useSelector(areaMasterSelector);
  const occupationMaster = useSelector(occupationMasterSelector);
  const isOpen = useModalState();
  const setModal = useSetModal();

  useEffect(() => {
    if (!dialogRef.current) return;
    if (isOpen) {
      dialogRef.current.showModal();
    }
  }, [isOpen]);

  useIsomorphicLayoutEffect(() => {
    if (isOpen) document.body.classList.toggle('scroll-lock', true);
  }, [isOpen]);

  useEffect(() => {
    if (!dialogRef.current) return;
    // モーダルが閉じられた際の処理
    const handleClose = () => {
      setModal(false);
    };
    dialogRef.current.addEventListener('close', handleClose);
    const refClosure = dialogRef.current;
    // eslint-disable-next-line consistent-return
    return () => {
      refClosure.removeEventListener('close', handleClose);
    };
  }, [setModal]);

  return (
    <MatchJobDialog
      dialogReference={dialogRef}
      areas={areaMaster}
      jobTypes={occupationMaster}
      startupMode
    />
  );
};
