import { FC, ReactNode, useEffect, useRef, useState } from 'react';
import styles from './index.module.scss';

const MIN_DISTANCE = 5;
const DRAG_SPEED = 1;

/**
 * DraggableSliderのprops
 */
interface DraggableSliderProps {
  children: ReactNode;
}

export const DraggableSlider: FC<DraggableSliderProps> = ({ children }) => {
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  const sliderRef = useRef<HTMLDivElement>(null);
  const dragStartRef = useRef<number | null>(null);

  const startDragging = (event: MouseEvent) => {
    if (!sliderRef.current) return;

    setIsDragging(true);
    setStartX(event.pageX - sliderRef.current.offsetLeft);
    setScrollLeft(sliderRef.current.scrollLeft);
    dragStartRef.current = event.pageX;
  };

  const stopDragging = () => {
    setIsDragging(false);
  };

  // NOTE: ドラッグ中にリンクがクリックされることを防ぐため
  const handleClick = (event: MouseEvent) => {
    const hasDragStarted =
      dragStartRef.current !== null && // ドラッグ操作が実行されていたかつ、
      Math.abs(dragStartRef.current - event.pageX) > MIN_DISTANCE; // ドラッグが一定距離以上移動した

    if (hasDragStarted) {
      event.preventDefault();
      event.stopPropagation();
    }

    dragStartRef.current = null;
  };

  useEffect(() => {
    const slider = sliderRef.current;

    const onDragging = (event: MouseEvent) => {
      if (!isDragging || !sliderRef.current) return;

      event.preventDefault();

      const xAfterMoved = event.pageX - sliderRef.current.offsetLeft;
      const walk = (xAfterMoved - startX) * DRAG_SPEED;
      sliderRef.current.scrollLeft = scrollLeft - walk;
    };

    if (slider) {
      slider.addEventListener('mousedown', startDragging);
      slider.addEventListener('mouseleave', stopDragging);
      slider.addEventListener('mouseup', stopDragging);
      slider.addEventListener('mousemove', onDragging);
      slider.addEventListener('click', handleClick, true);

      return () => {
        slider.removeEventListener('mousedown', startDragging);
        slider.removeEventListener('mouseleave', stopDragging);
        slider.removeEventListener('mouseup', stopDragging);
        slider.removeEventListener('mousemove', onDragging);
        slider.removeEventListener('click', handleClick, true);
      };
    }

    return undefined;
  }, [isDragging, scrollLeft, startX]);

  return (
    <div className={styles.draggableSlider__container} ref={sliderRef}>
      {children}
    </div>
  );
};
