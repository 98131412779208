import { NullableString } from '../../constants';

/**
 * 採用担当者画像表示関数
 * @param recruiterImage 採用担当者画像
 * @param contentType 採用担当者画像コンテントタイプ
 * @returns 「採用担当者画像」「ダミー画像」どちらの画像を表示するか
 */
export const getImage = (
  recruiterImage: NullableString,
  contentType: NullableString
) => {
  const recruiterImageType = recruiterImage
    ? `data:${contentType};base64,${recruiterImage}`
    : `http://src.doda.jp/resources/dcfront/images/img_recruiter_001.png`;

  return recruiterImageType;
};
