import { FC } from 'react';
import { Box, Text } from '@doda/io-react/common';
import { TextLink } from '../TextLink';
import styles from './index.module.scss';
import { createOnAnchorClick } from '../../logics/createOnAnchorClick';

/**
 * BusinessClientInfoListProps
 * @param heading 見出し
 * @param businessClientLink 法人のお客さまへ内リンク用配列
 * @param isLoggedIn ログイン状態
 */
export interface BusinessClientInfoListProps {
  heading: string;
  businessClientInfoLink: BusinessClientInfoLink[];
}

/**
 * BusinessClientInfoLink
 * @param href 法人のお客さまへ内ページURL
 * @param text 法人のお客さまへ内お知らせ内容
 * @param externalWindow 外部ウィンドウで開くかどうか
 * @param params - リンククリック時に付与されるクエリパラメータ
 * @param usrclk ログイン/ログアウト時に付与されるパラメータ
 */
export interface BusinessClientInfoLink {
  href: string;
  text: string;
  externalWindow: boolean;
  // params: Record<string, string>;
  // usrclk: string;
  destinationUrl: string;
  /** a タグの target 属性 */
  target?: '_blank';
  /** a タグの rel 属性 */
  rel?: 'noopener';
}

export const BusinessClientInfo: FC<BusinessClientInfoListProps> = ({
  heading,
  businessClientInfoLink,
}) => {
  return (
    <Box
      color="white"
      border="gray"
      radius="4"
      className={styles.businessClientInfo}
    >
      <Text as="h3" weight="bold" size="12">
        {heading}
      </Text>
      <ul className={styles.businessClientInfo__list}>
        {businessClientInfoLink.map((link) => {
          const onClick = createOnAnchorClick(
            link.destinationUrl,
            link.externalWindow
          );
          return (
            <li key={link.href}>
              <TextLink
                href={link.href}
                target={link.target}
                rel={link.rel}
                onClick={onClick}
              >
                {link.text}
              </TextLink>
            </li>
          );
        })}
      </ul>
    </Box>
  );
};
