import { Button } from '@doda/io-react/common';

import styles from './index.module.scss';

/** AppliedButton */
interface Props {
  /** サイズ */
  size?: 'M' | 'S';
  /** 幅 */
  width?: string;
}

export const AppliedButton = ({ size = 'M', width }: Props) => {
  return (
    <div
      style={{
        width,
      }}
    >
      <Button
        size={size}
        variant="outline_blue"
        widthPercentage={width ? 100 : undefined}
        disabled
        className={styles.appliedButton}
      >
        応募済
      </Button>
    </div>
  );
};
