import React, { FC } from 'react';
import { ImageLinkList } from '@doda/io-react/common';
import styles from './index.module.scss';
import { MoveToListLinkNoBackground } from '../MoveToListLinkNoBackground';
import { ListItem } from './components/ListItem';
import { ListData } from './types';
import { createOnAnchorClick } from '../../logics/createOnAnchorClick';

/**
 * 職種一覧セクションのProps
 */
interface Props {
  /** 左列に表示する職種一覧 */
  listDataLeft: ListData[];
  /** 右列に表示する職種一覧 */
  listDataRight: ListData[];
}

export const JobTypesSection: FC<Props> = ({ listDataLeft, listDataRight }) => {
  const jobTypesLeftForDisplay = listDataLeft.map((jobType) => {
    return {
      image: jobType.image,
      imageAlt: jobType.name,
      href: jobType.href,
      onClick: createOnAnchorClick(jobType.usrclk, false),
      children: (
        <ListItem
          jobType={jobType.name}
          jobCount={jobType.jobCount}
          subCategories={jobType.jobExamples}
        />
      ),
    };
  });

  const jobTypesRightForDisplay = listDataRight.map((jobType) => {
    return {
      image: jobType.image,
      imageAlt: jobType.name,
      href: jobType.href,
      onClick: createOnAnchorClick(jobType.usrclk, false),
      children: (
        <ListItem
          jobType={jobType.name}
          jobCount={jobType.jobCount}
          subCategories={jobType.jobExamples}
        />
      ),
    };
  });

  return (
    <section className={styles.jobTypesSection}>
      <div className={styles.jobTypesSection__headingWrapper}>
        <h2 className={styles.jobTypesSection__heading}>職種から探す</h2>
      </div>
      <div className={styles.jobTypesSection__linkWrapper}>
        <div className={styles.jobTypesSection__linkListWrapper}>
          <ImageLinkList
            listData={jobTypesLeftForDisplay}
            arrow="gray"
            className={styles.jobTypesSection__imageLinkList}
          />
        </div>
        <div className={styles.jobTypesSection__linkListWrapper}>
          <ImageLinkList
            listData={jobTypesRightForDisplay}
            arrow="gray"
            className={styles.jobTypesSection__imageLinkList}
          />
        </div>
      </div>
      <div className={styles.jobTypesSection__toDetailLinkWrapper}>
        <MoveToListLinkNoBackground
          href="/kyujin/shokushu/"
          text="全職種一覧"
        />
      </div>
    </section>
  );
};
