import React from 'react';
import Head from 'next/head';
import Script from 'next/script';

export const PushNotification = () => {
  return (
    <Head>
      <link rel="manifest" href="/manifest.json" />
      <Script
        defer
        src="https://www.gstatic.com/firebasejs/5.9.1/firebase-app.js"
      />
      <Script
        defer
        src="https://www.gstatic.com/firebasejs/5.9.1/firebase-messaging.js"
      />
      <Script defer src="/cmn_web/js/front/webpush_rc.js" />
    </Head>
  );
};
