import { AxiosResponse } from 'axios';
import jsonpAdapter from 'axios-jsonp';
import { appAxios } from '@doda/common/src/libs/axios/appAxios';
import {
  Suggest,
  SuggestItems,
} from '@doda/common/src/interfaces/suggestDispatcher';

/**
 * 候補検索取得API
 * @param {string} searchRange 検索範囲値
 * @param {string} searchText 検索条件
 * @param {string} index
 * @returns {SuggestItems}
 */
export const getSuggestDispatcher = async (
  searchRange: string,
  searchText: string,
  index = 0
): Promise<SuggestItems['items']> => {
  return appAxios({
    url: '/termsuggester/Dispatcher',
    adapter: jsonpAdapter,
    callbackParamName: 'jsoncallback',
    params: {
      x: index,
      d: searchRange,
      q: searchText,
    },
  })
    .then((res: AxiosResponse<Suggest>) => {
      return res.data.d[0];
    })
    .catch();
};
