import { FC, MouseEventHandler } from 'react';
import { SuggestItem } from '@doda/common/src/interfaces/suggestDispatcher';
import parse from 'html-react-parser';
import classNames from 'classnames';
import style from './index.module.scss';

/**
 * トップページキーワードサジェストリストのProps
 */
interface Props {
  keywordSuggestStyle?: string;
  /** キーワードサジェストAPIで取得したキーワード*/
  suggests: SuggestItem[];
  /** キーワードをクリックしたときの関数*/
  onClickSuggest: MouseEventHandler<HTMLButtonElement>;
  /** キーワードリスト以外をクリックした際にリストを非表示にする*/
  closeSuggestions: MouseEventHandler<HTMLButtonElement>;
  /** リストを表示にするか否か*/
  shouldShowSuggestions: boolean;
}

export const SuggestList: FC<Props> = ({
  keywordSuggestStyle,
  suggests,
  onClickSuggest,
  closeSuggestions,
  shouldShowSuggestions,
}) => {
  if (!shouldShowSuggestions || !suggests.length) return null;
  return (
    <>
      <button
        type="button"
        className={style.keywordSuggest__background}
        onClick={closeSuggestions}
      />
      <div
        className={classNames(keywordSuggestStyle, style.keywordSuggest__menu)}
      >
        {suggests.map((suggest) => (
          <button
            key={suggest.l}
            type="button"
            className={style.keywordSuggest__button}
            value={suggest.t}
            onClick={onClickSuggest}
          >
            {parse(suggest.l)}
          </button>
        ))}
      </div>
    </>
  );
};
