import { BalloonNotice } from '@doda/io-react/common';
import styles from './index.module.scss';

/** FavoriteBalloonNotice Props*/
interface Props {
  /** 表示フラグ */
  isShow?: boolean;
}

/**
 * FavoriteBalloonNotice コンポーネント
 * @param {Props} props - コンポーネントのプロパティ
 * @param {boolean} [props.isShow] - バルーン通知を表示するかどうかを示すフラグ
 * @returns {JSX.Element} - BalloonNoticeコンポーネント
 */
export const FavoriteBalloonNotice = ({ isShow }: Props) => {
  return (
    <BalloonNotice
      left={0}
      top={0}
      tailY="bottom"
      width={230}
      show={isShow}
      className={styles.favoriteBalloonNotice}
    >
      <p className={styles.favoriteBalloonNotice__heading}>
        気になるリストに保存しました
      </p>
      <p className={styles.favoriteBalloonNotice__text}>
        「気になるリストへ」のボタンから、気になるリスト一覧へ移動できます
      </p>
    </BalloonNotice>
  );
};
