import { useState } from 'react';

export const SLIDE_TO_SHOW = 3;
const SLIDER_THRESHOLD = 4;

/**
 * ToDoリストに必要なデータを返すフック
 * @param todoListCount - ToDoの数
 * @returns ToDoリストに必要なデータ
 */
export const useTodoSlider = (todoListCount: number) => {
  const [isLeftEnd, setIsLeftEnd] = useState(true);
  const [isRightEnd, setIsRightEnd] = useState(false);

  const isSlider = todoListCount >= SLIDER_THRESHOLD;

  const afterChange = (currentSlide: number) => {
    setIsRightEnd(currentSlide === todoListCount - SLIDE_TO_SHOW);
    setIsLeftEnd(currentSlide === 0);
  };

  return { isSlider, isLeftEnd, isRightEnd, afterChange };
};
