import { combineReducers } from '@reduxjs/toolkit';
import { hydrateReducer } from '@doda/common/src/redux/utils/hydrateReducer';
import {
  memberInfoReducer,
  memberInfoInitialState,
} from '@doda/common/src/redux/features/memberInfo';
import {
  analyticsReducer,
  analyticsInitialState,
} from '@doda/common/src/redux/features/analytics';
import {
  masterInitialState,
  masterReducer,
} from '@doda/common/src/redux/features/master';

// TODO: TOPページ実装時に適切なreducerに書き換える
const reducers = combineReducers({
  memberInfo: memberInfoReducer,
  master: masterReducer,
  analytics: analyticsReducer,
});

const initialState = {
  memberInfo: memberInfoInitialState,
  master: masterInitialState,
  analytics: analyticsInitialState,
};

export const reducer = hydrateReducer(reducers, initialState);

export type TopRootState = ReturnType<typeof reducer>;
