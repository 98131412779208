import { NullableString } from '../../constants';
/**
 * タイトル作成関数
 * @param companyName 会社名
 * @param offer オファー内容
 * @returns タイトルに表示する内容
 */
export const getTitle = (
  companyName: NullableString,
  offer: 'プレミアムオファー' | '面接確約オファー'
) => {
  const titleText = companyName
    ? `${companyName}より${offer}が届いています`
    : `${offer}が届いています`;

  return titleText;
};
