import { FC } from 'react';
import { ArrowRight, Box } from '@doda/io-react/common';
import classNames from 'classnames';
import styles from './index.module.scss';

export type TodoIconType =
  | 'counselingDate'
  | 'interviewDateSetting'
  | 'dateSetting'
  | 'message'
  | 'resumeFileUpload'
  | 'counseling'
  | 'desiredCondition';

/**
 * Todoリストのカードコンポネントのprops
 */
interface Props {
  title: string;
  url: string;
  iconType: TodoIconType;
  description?: string;
  /** Todoクリックハンドラー関数 */
  handleClickTodo: VoidFunction;
}

export const TodoListCard: FC<Props> = ({
  title,
  iconType,
  url,
  description,
  handleClickTodo,
}) => {
  return (
    <Box color="white" radius="4" boxShadow="blue">
      <a
        href={url}
        className={styles.toDoListCard__anchor}
        onClick={handleClickTodo}
      >
        <div
          className={classNames(
            styles.toDoListCard__icon,
            styles[`toDoListCard__icon--${iconType}`]
          )}
        />
        <div className={styles.toDoListCard__todoCardTitleArea}>
          <h4 className={styles.toDoListCard__todoCardTitle}>{title}</h4>
          {description && (
            <p className={styles.toDoListCard__todoCardDescription}>
              {description}
            </p>
          )}
        </div>
        <ArrowRight size="XS" color="gray" />
      </a>
    </Box>
  );
};
