import { Result as RecommendJob } from '@doda/common/src/interfaces/api/recommendPanel';
import { useAppDispatch } from '@doda/common/src/redux/hooks';
import {
  measureClickRecommend,
  measureRecommendBookmarkButtonClick,
  measureRecommendVisible,
} from '../../logics/analytics';
import { useIntersectionObserver } from '../useIntersectionObserver';
import { getUsrclkQuery } from '../../logics/getUsrclkQuery';

/**
 * レコメンド計測hook
 * @param isLoggedIn ログイン状態
 * @returns レコメンド求人に関する計測関数一覧object
 */
export const useMeasureRecommend = (isLoggedIn: boolean) => {
  const dispatch = useAppDispatch();
  const handleIntersect = (
    recommendType: string,
    recommendJobList: RecommendJob[]
  ) => {
    dispatch(measureRecommendVisible(recommendType, recommendJobList));
  };
  const { setObserverRef } =
    useIntersectionObserver<[string, RecommendJob[]]>(handleIntersect);
  const handleClickThumbnail = (recommendJob: RecommendJob) => {
    dispatch(measureClickRecommend(recommendJob, isLoggedIn));
  };
  const handleClickCardDetail = (recommendJob: RecommendJob) => {
    dispatch(measureClickRecommend(recommendJob, isLoggedIn));
  };
  const handleClickFavorite = (recommendJob: RecommendJob) => {
    dispatch(measureRecommendBookmarkButtonClick(recommendJob.itemNo));
  };
  const getUserClickQueryParam = (recommendType: string): string => {
    switch (recommendType) {
      case 'newjob_list':
        return 'kyujinRecommendArea_newArrivalDetail';
      default:
        return 'kyujinRecommendArea_recommendDetail';
    }
  };
  const getQueryParamOnClick = (recommendType: string) =>
    getUsrclkQuery(isLoggedIn, getUserClickQueryParam(recommendType));

  return {
    setObserverRef,
    handleClickThumbnail,
    handleClickCardDetail,
    handleClickFavorite,
    getQueryParamOnClick,
  };
};
