import { FC } from 'react';
import { Checkbox, Text } from '@doda/io-react/common';
import { useCountUpdate } from '@doda/common/src/hooks/useCountUp';
import styles from './index.module.scss';
import { QuickSearchSelectLogIn } from '../QuickSearchSelectLogIn';
import { useQuickSearch } from '../../hooks/useQuickSearch';
import { SuggestList } from '../SuggestList';
/**
 *クイック検索表示用のProps
 */
interface Props {
  /** 掲載更新日 */
  publicationDate: string;
  /** 表示するコンポーネントタイプ */
  totalCount: number;
}

export const QuickSearchLogIn: FC<Props> = ({
  publicationDate,
  totalCount,
}) => {
  const isLoggedIn: boolean = true;
  const {
    activeBalloon,
    toggleBalloon,
    handleChange,
    keyword,
    suggestions,
    isNewArrival,
    onCheckboxClick,
    handleSuggestClick,
    handleSelectedItem,
    closeSuggestions,
    shouldShowSuggestions,
    onFocusSuggestions,
    searchButtonOnClick,
    onKeyDown,
  } = useQuickSearch(isLoggedIn);

  const countUpRef = useCountUpdate(totalCount);

  return (
    <>
      <div className={styles.quickSearchLogInHeader}>
        <div className={styles.quickSearchLogInHeader__jobCountArea}>
          <span className={styles.quickSearchLogInHeader__jobCountText}>
            公開求人
          </span>
          <span
            className={styles.quickSearchLogInHeader__jobCount}
            ref={countUpRef}
          />

          <span className={styles.quickSearchLogInHeader__jobCountNumber}>
            件
          </span>
          <span className={styles.quickSearchLogInHeader__publicationDate}>
            {publicationDate}更新
          </span>
          <Text size="12" className={styles.quickSearchLogInHeader__UpdateDate}>
            毎週月・木曜更新
          </Text>
        </div>
      </div>
      <div className={styles.quickSearchLogIn__list}>
        <div className={styles.quickSearchLogInSelectBox__wrap}>
          <QuickSearchSelectLogIn
            type="occupation"
            isActive={activeBalloon === 'occupation'}
            onToggle={() => toggleBalloon('occupation')}
            onSelectItem={handleSelectedItem}
          />
          <QuickSearchSelectLogIn
            type="location"
            isActive={activeBalloon === 'location'}
            onToggle={() => toggleBalloon('location')}
            onSelectItem={handleSelectedItem}
          />
          <QuickSearchSelectLogIn
            type="income"
            isActive={activeBalloon === 'income'}
            onToggle={() => toggleBalloon('income')}
            onSelectItem={handleSelectedItem}
          />
          <div>
            <div className={styles.quickSearchLogInInput__wrap}>
              <input
                className={styles.quickSearchLogIn__input}
                type="text"
                placeholder="スキルや条件など"
                value={keyword}
                onChange={handleChange}
                onFocus={onFocusSuggestions}
                maxLength={100}
                onKeyDown={onKeyDown}
              />
              <div className={styles.quickSearchLogInCheckbox__wrap}>
                <Checkbox
                  value={1}
                  onChange={onCheckboxClick}
                  label="新着のみ"
                  checked={isNewArrival}
                />
              </div>
            </div>
            <SuggestList
              keywordSuggestStyle={styles.quickSearchLogIn__suggestList}
              suggests={suggestions}
              onClickSuggest={handleSuggestClick}
              closeSuggestions={closeSuggestions}
              shouldShowSuggestions={shouldShowSuggestions}
            />
          </div>
        </div>
        <button
          type="button"
          onClick={searchButtonOnClick}
          className={styles.quickSearchLogIn__button}
        >
          <span className={styles.quickSearchLogIn__searchIcon} />
        </button>
      </div>
    </>
  );
};
