import {
  MATCH_JOB_LIST_Link,
  MATCH_JOB_LIST_Link_WITH_NEW_ARRIVAL,
} from '../../constants';

/**
 * 希望にマッチした求人項目のデータ作成用関数
 * @param isDesiredCond [desired_cond]の area1,income,job1 全てに "skip" が設定されているか
 * @param isDesiredCondCookie Cookieに[desired_cond]が存在するか
 * @param matchJobCount 希望条件にマッチした求人の件数
 * @param hasNewArrival 新着求人が存在するか
 * @returns 希望にマッチした求人項目のデータ
 */
export const getMachJob = (
  isDesiredCond: boolean,
  isDesiredCondCookie: boolean,
  matchJobCount: number,
  hasNewArrival: boolean
) => {
  const baseMatchJobUrl = hasNewArrival
    ? MATCH_JOB_LIST_Link_WITH_NEW_ARRIVAL
    : MATCH_JOB_LIST_Link;
  const matchJobUrl = isDesiredCond ? baseMatchJobUrl : undefined;

  const jobCount = isDesiredCondCookie ? matchJobCount : undefined;

  return { matchJobUrl, jobCount };
};
