export const webSiteJsonData = {
  '@context': 'https://schema.org',
  '@type': 'WebSite',
  name: 'doda',
  url: 'https://doda.jp/',
};

export const webPageJsonData = {
  '@context': 'https://schema.org',
  '@type': 'WebPage',
  name: '転職ならdoda(デューダ) 求人､転職情報満載の転職サイト',
  description:
    'dodaは業界最大級の支援実績で、転職相談、求人紹介・検索・応募、企業からのスカウト、面接対策のお手伝いまで転職を全面的にサポートする完全無料の転職サイト。大手・優良企業、完全週休二日、未経験者歓迎など求人多数。',
  url: 'https://doda.jp/',
  primaryImageOfPage: {
    '@type': 'ImageObject',
    url: 'https://src.doda.jp/cmn_web/img/index/topPrimaryImageOfPage.jpg',
    representativeOfPage: true,
    caption: '転職ならdoda 変えるなら、きっと今だ。',
  },
};
