import { getPrefix } from '../getPrefix';

// TODO: getUsrclk に差し替えてからこの関数は削除する
/**
 * ログイン状態に基づいて、&usrclk=として付与されるクエリパラメータのバリューを返す関数
 * @param isLoggedIn - ログイン済みかどうかのフラグ
 * @param usrclk - &usrclk=として付与されるクエリパラメータ
 * @returns usrclkクエリパラメータ
 */
export const getUsrclkQuery = (isLoggedIn: boolean, usrclk: string) => {
  if (!usrclk) return '';

  const prefix = getPrefix(isLoggedIn);
  return `usrclk=${prefix + usrclk}`;
};
