import { Button, HeartAnimation, Heart } from '@doda/io-react/common';
import { MouseEventHandler } from 'react';
import styles from './index.module.scss';

/**
 * BookmarkListLink コンポーネントのプロパティ
 */
interface Props {
  /** ボタンのリンク先URL */
  url: string;
  /** ボタンがクリックされたときのハンドラー（任意） */
  onClick?: MouseEventHandler<HTMLAnchorElement>;
  /** デフォルト状態が保存されているかどうかのフラグ（任意） */
  isDefaultStateSaved?: boolean;
  /** ボタンのサイズ（任意） */
  size?: 'M' | 'S';
  /** ボタンの幅（任意） */
  width?: string;
}

/**
 * BookmarkListLink コンポーネント
 * @param {Props} props - BookmarkListLink コンポーネントのプロパティ
 * @returns {JSX.Element} レンダリングされたコンポーネント
 */
export const BookmarkListLink = ({
  url,
  onClick,
  isDefaultStateSaved,
  size = 'M',
  width,
}: Props): JSX.Element => {
  return (
    <div
      style={{
        width,
      }}
    >
      <Button
        onClick={onClick}
        size={size}
        variant="outline_blue"
        widthPercentage={width ? 100 : undefined}
        as="a"
        href={url}
        className={styles.bookmarkListLink}
      >
        {isDefaultStateSaved ? (
          <Heart color="orange" size={size} />
        ) : (
          <HeartAnimation size={size} />
        )}
        気になるリストへ
      </Button>
    </div>
  );
};
