import {
  MESSAGE_TITLE_PREMIUM_OFFER,
  MESSAGE_TITLE_INTERVIEW_OFFER,
} from '../../constants';

/**
 * スカウト理由表示関数
 * @param scoutReason スカウト理由(文字列か空の文字列)
 * @returns 「プレミアムオファー」「面接確約オファー」どちらのスカウト理由を表示するか
 */
export const getOfferText = (scoutReason: string | null) => {
  const scoutReasonType = scoutReason
    ? MESSAGE_TITLE_PREMIUM_OFFER
    : MESSAGE_TITLE_INTERVIEW_OFFER;

  return scoutReasonType;
};
