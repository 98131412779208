import { ComponentProps, FC } from 'react';
import styles from './index.module.scss';

type TextLinkProps = Omit<ComponentProps<'a'>, 'className'>;

export const TextLink: FC<TextLinkProps> = ({ children, ...props }) => {
  return (
    // a タグの props を全部渡すようにするため
    // eslint-disable-next-line react/jsx-props-no-spreading
    <a {...props} className={styles.textLink}>
      ・{children}
    </a>
  );
};
