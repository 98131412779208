import {
  ChangeEventHandler,
  FocusEventHandler,
  KeyboardEventHandler,
  MouseEventHandler,
  useEffect,
  useState,
} from 'react';
import { getSuggestDispatcher } from '@doda/common/src/services/suggestDispatcherAPI';
import { SuggestItem } from '@doda/common/src/interfaces/suggestDispatcher';
import { QuickSearchType } from '../../types';
import { useDebounce } from '../useDebounce';
import { createRequestQuery } from '../../logics/createRequestQuery';
import { useMeasureFreeWord } from '../useMeasureFreeWord';

const MIN_SUGGEST_TRIGGER_LENGTH = 3;
/**
 * useQuickSearch - クイック検索機能に関する状態管理とロジックを提供するカスタムフック。
 *
 * @param isLoggedIn - -ログイン判定
 * @returns クイック検索機能の状態とハンドラーを返します。
 */
export const useQuickSearch = (isLoggedIn: boolean) => {
  const [activeBalloon, setActiveBalloon] = useState<QuickSearchType | null>(
    null
  );

  const [keyword, setKeyword] = useState<string>('');
  const [suggestions, setSuggestions] = useState<SuggestItem[]>([]);
  const [shouldShowSuggestions, setShouldShowSuggestions] = useState(false);

  const [isNewArrival, setIsNewArrival] = useState(false);
  const onCheckboxClick = () => {
    setIsNewArrival((prev) => !prev);
  };

  const debouncedInputText = useDebounce(keyword, 300);
  useMeasureFreeWord(keyword.length);

  const closeBalloon = () => {
    setActiveBalloon(null);
  };

  const closeSuggestions = () => {
    setShouldShowSuggestions(false);
  };

  const openSuggestions = () => {
    setShouldShowSuggestions(true);
  };

  const toggleBalloon = (type: QuickSearchType) => {
    setActiveBalloon((prevActiveBalloon) =>
      prevActiveBalloon === type ? null : type
    );
    closeSuggestions();
  };

  const onFocusSuggestions: FocusEventHandler<HTMLInputElement> = (event) => {
    const { value } = event.target;

    closeBalloon();
    if (value.length >= MIN_SUGGEST_TRIGGER_LENGTH) openSuggestions();
  };

  useEffect(() => {
    if (debouncedInputText.length < MIN_SUGGEST_TRIGGER_LENGTH) {
      closeSuggestions();
      return;
    }

    getSuggestDispatcher('free', debouncedInputText).then((res) => {
      const resSuggestions = res.map((item) => item);
      setSuggestions(resSuggestions);
    });
  }, [debouncedInputText]);

  const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const { value } = event.target;
    setKeyword(value);
    if (value.length >= MIN_SUGGEST_TRIGGER_LENGTH) openSuggestions();
  };

  const handleSuggestClick: MouseEventHandler<HTMLButtonElement> = (event) => {
    const suggest = event.currentTarget.value;
    setKeyword(suggest);
    closeSuggestions();
  };

  const [occupation, setOccupation] = useState<string>('');
  const [location, setLocation] = useState<string>('');
  const [income, setIncome] = useState<string>('');

  const handleSelectedItem = (item: string, type: string) => {
    switch (type) {
      case 'occupation':
        setOccupation(item);
        break;
      case 'location':
        setLocation(item);
        break;
      case 'income':
        setIncome(item);
        break;
      default:
        break;
    }
  };

  const moveToSearchResult = () => {
    const url = createRequestQuery(
      isLoggedIn,
      occupation,
      location,
      income,
      keyword,
      isNewArrival
    );
    window.location.href = url;
  };

  const searchButtonOnClick: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault();
    moveToSearchResult();
  };

  const onKeyDown: KeyboardEventHandler<HTMLInputElement> = (event) => {
    if (event.key === 'Enter') moveToSearchResult();
  };

  return {
    activeBalloon,
    toggleBalloon,
    handleChange,
    keyword,
    suggestions,
    isNewArrival,
    onCheckboxClick,
    handleSuggestClick,
    handleSelectedItem,
    closeSuggestions,
    shouldShowSuggestions,
    onFocusSuggestions,
    searchButtonOnClick,
    onKeyDown,
  };
};
