import { FC } from 'react';
import { ArrowRight, Text, SectionTitle } from '@doda/io-react/common';
import styles from './index.module.scss';
import { createOnAnchorClick } from '../../logics/createOnAnchorClick';

/**
 * あなたへのおすすめ内コンテンツ
 */
export interface RecommendForYouLink {
  /** コンテンツURL */
  url: string;
  /** コンテンツテキスト */
  text: string;
  /** コンテンツ画像 */
  image: string;
  /** コンテンツ画像Alt */
  alt: string;
  /** 外部ウィンドウで開くかどうか */
  externalWindow: boolean;
  /** クエリパラメータ付きの生成されたURL */
  destinationUrl: string;
  /** a タグの target 属性 */
  target?: '_blank';
  /** a タグの rel 属性 */
  rel?: 'noopener';
}

/**
 * あなたへのおすすめコンポーネントのprops
 */
export interface RecommendForYouProps {
  /** 見出しテキスト */
  heading: string;
  /** あなたへのおすすめ内リンクリスト */
  recommendForYouLinks: RecommendForYouLink[];
}

export const RecommendForYou: FC<RecommendForYouProps> = ({
  heading,
  recommendForYouLinks,
}) => {
  return (
    <div className={styles.recommendForYou__container}>
      <SectionTitle as="h2" bgType="none" size="M">
        {heading}
      </SectionTitle>
      <ul className={styles.recommendForYou__list}>
        {recommendForYouLinks.map((link) => {
          const onClick = createOnAnchorClick(
            link.destinationUrl,
            link.externalWindow
          );

          return (
            <li className={styles.recommendForYou__listDetail} key={link.url}>
              <a
                href={link.url}
                className={styles.recommendForYou__listDetailLink}
                onClick={onClick}
                target={link.target}
                rel={link.rel}
              >
                <img
                  src={link.image}
                  alt={link.alt}
                  className={styles.recommendForYou__listDetailImage}
                />
                <Text
                  size="14--articlePC"
                  color="blue"
                  weight="bold"
                  className={styles.recommendForYou__listDetailText}
                >
                  {link.text}
                </Text>
                <ArrowRight color="blue" size="XS" />
              </a>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
