import {
  useReducer,
  useEffect,
  ComponentProps,
  MouseEventHandler,
} from 'react';
import { FavoriteBalloonNotice } from '../FavoriteBalloonNotice';
import { SaveBookmarkButton } from '../SaveBookmarkButton';

import styles from '../../index.module.scss';
import { AppliedButton } from '../AppliedButton';
import { BookmarkListLink } from '../BookmarkListLink';

type SaveBookmarkButton = ComponentProps<typeof SaveBookmarkButton>;

/**
 * お気に入りのViewコンポーネントのProps
 */
export interface Props {
  /** 現在の状態 ("default" | "loading" | "saved" | "applied") */
  state: 'default' | 'loading' | 'saved' | 'applied';
  /** ブックマークリストのURL */
  bookmarkListUrl: string;
  /** お気に入りボタンがクリックされたときのイベントハンドラ（オプション） */
  onClickFavorite?: MouseEventHandler<HTMLButtonElement>;
  /** 保存されたボタンがクリックされたときのイベントハンドラ（オプション） */
  onClickSaved?: MouseEventHandler<HTMLAnchorElement>;
  /** ボタンのサイズ ("M" | "S") */
  size?: 'M' | 'S';
  /** ボタンの幅を指定する文字列（オプション） */
  width?: string;
  /** フェードアウトのトリガー ("scroll" | "timer") */
  fadeTrigger?: 'scroll' | 'timer';
  /** バルーン非表示フラグ */
  withoutBalloon?: boolean;
}

/**
 * View コンポーネント
 * @param {Props} props - コンポーネントのプロパティ
 * @param {"default" | "loading" | "saved" | "applied"} props.state - 現在の状態
 * @param {string} props.bookmarkListUrl - ブックマークリストのURL
 * @param {MouseEventHandler<HTMLButtonElement>} [props.onClickFavorite] - お気に入りボタンがクリックされたときのイベントハンドラ
 * @param {MouseEventHandler<HTMLAnchorElement>} [props.onClickSaved] - 保存されたボタンがクリックされたときのイベントハンドラ
 * @param {"M" | "S"} [props.size] - ボタンのサイズ
 * @param {string} [props.width] - ボタンの幅
 * @param {"scroll" | "timer"} [props.fadeTrigger="scroll"] - フェードアウトのトリガー
 * @returns {JSX.Element} - Viewコンポーネント
 */
export const View = ({
  state,
  onClickFavorite,
  onClickSaved,
  size,
  width,
  bookmarkListUrl,
  fadeTrigger = 'scroll',
  withoutBalloon = false,
}: Props) => {
  // stateがsavedに変わった場合：
  //   ・バルーンを表示する
  //   ・アニメーションのハートを表示する
  // スクロール時にバルーンを除去する
  const [isShowToSavedAnimation, hiddenBalloon] = useReducer(
    () => false,
    state !== 'saved'
  );
  useEffect(() => {
    if (state === 'saved') {
      if (fadeTrigger === 'scroll') {
        window.addEventListener('scroll', hiddenBalloon);
      }
      if (fadeTrigger === 'timer') {
        setTimeout(hiddenBalloon, 5000);
      }
    }
    return () => {
      window.removeEventListener('scroll', hiddenBalloon);
    };
  }, [state, fadeTrigger]);

  return (
    <div
      className={styles.favorite}
      style={{
        width,
      }}
    >
      <div className={styles.favoriteBalloonNoticeWrapper}>
        <FavoriteBalloonNotice
          isShow={
            isShowToSavedAnimation && state === 'saved' && !withoutBalloon
          }
        />
      </div>
      {(state === 'default' || state === 'loading') && (
        <SaveBookmarkButton
          onClick={onClickFavorite}
          isLoading={state === 'loading'}
          size={size}
          width={width}
        />
      )}
      {state === 'saved' && (
        <BookmarkListLink
          url={bookmarkListUrl}
          onClick={onClickSaved}
          isDefaultStateSaved={!isShowToSavedAnimation}
          size={size}
          width={width}
        />
      )}
      {state === 'applied' && <AppliedButton size={size} width={width} />}
    </div>
  );
};
