import { convertQueryToStaticUrl } from '@doda/common/src/logics/staticUrl';
import { DODA_SITE_URL } from '@doda/common/src/constants/urls';
import {
  CANONICAL_KEYS,
  LOGIN_PREFIX,
  LOGOUT_PREFIX,
  QUERY_KEY_NAME,
  QUICK_SEARCH_STATIC_PARAM,
  QUICK_SEARCH_USRCLK,
} from '../../constants';
import { getRequestParamMap } from '../getRequestParamMap';
import { getSearchParams } from '../getSearchParams';
import { createMeasurementValue } from '../createMeasurementParameters';

/**
 * 検索ボタン押下時にURLを作成するロジック
 * @param isLoggedIn
 * @param occupation - 職種
 * @param location - 勤務地
 * @param income - 年収
 * @param keyword - キーワード
 * @param isNewArrival - 新着かどうか
 * @returns クイックサーチ求人検索用url
 */
export const createRequestQuery = (
  isLoggedIn: boolean,
  occupation?: string,
  location?: string,
  income?: string,
  keyword?: string,
  isNewArrival?: boolean
): string => {
  // requestParamを作成
  const requestParamMap = getRequestParamMap(
    occupation,
    location,
    income,
    keyword,
    isNewArrival
  );

  // 計測パラメータを取得
  const measurementParam = createMeasurementValue(requestParamMap, isLoggedIn);

  // 固定値+計測パラメータ結合
  const measurementParamSuffix = measurementParam ? `_${measurementParam}` : '';
  const prefix = isLoggedIn ? LOGIN_PREFIX : LOGOUT_PREFIX;
  const usrclk = `usrclk=${prefix}${QUICK_SEARCH_USRCLK}${measurementParamSuffix}`;
  const measurementParams = `${QUICK_SEARCH_STATIC_PARAM}&${usrclk}`;

  // 値が空の時は固定値を付けて返却
  if (!requestParamMap.size) {
    return `${DODA_SITE_URL.jobSearchList}?${measurementParams}`;
  }
  // 検索条件が[キーワードのみ] の場合は正規化
  const hasKeywordOnly =
    requestParamMap.has(QUERY_KEY_NAME.keyword) && requestParamMap.size === 1;
  if (hasKeywordOnly) {
    const baseUrl = convertQueryToStaticUrl(
      { [QUERY_KEY_NAME.keyword]: requestParamMap.get(QUERY_KEY_NAME.keyword) },
      DODA_SITE_URL.jobSearchList
    );
    return baseUrl.concat('?', measurementParams);
  }

  // 検索条件が[キーワード + 新着のみ] の場合は正規化
  const hasKeywordAndNewArrivalOnly =
    requestParamMap.has(QUERY_KEY_NAME.keyword) &&
    requestParamMap.has(QUERY_KEY_NAME.isNewArrival) &&
    requestParamMap.size === 2;
  if (hasKeywordAndNewArrivalOnly) {
    const baseUrl = convertQueryToStaticUrl(
      {
        [QUERY_KEY_NAME.isNewArrival]: requestParamMap.get(
          QUERY_KEY_NAME.isNewArrival
        ),
        [QUERY_KEY_NAME.keyword]: requestParamMap.get(QUERY_KEY_NAME.keyword),
      },
      DODA_SITE_URL.jobSearchList
    );
    return baseUrl.concat('?', measurementParams);
  }

  // 検索条件にキーワードを含まない場合は正規化
  const hasNoKeyword = !requestParamMap.has(QUERY_KEY_NAME.keyword);
  if (hasNoKeyword) {
    const sortedRequestParams = CANONICAL_KEYS.reduce((sortedObj, key) => {
      if (requestParamMap.has(key)) sortedObj[key] = requestParamMap.get(key);
      return sortedObj;
    }, {});
    const baseUrl = convertQueryToStaticUrl(
      sortedRequestParams,
      DODA_SITE_URL.jobSearchList
    );
    return baseUrl.concat('?', measurementParams);
  }

  // 残りのケース（検索条件にキーワードを含む場合）では非正規化
  requestParamMap.delete(QUERY_KEY_NAME.preBtn);
  const queryParams = Object.fromEntries(requestParamMap);
  const searchParams = getSearchParams(queryParams);
  return `${DODA_SITE_URL.jobSearchList}?${measurementParams}&${searchParams}`;
};
