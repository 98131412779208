import React, { FC } from 'react';
import styles from './index.module.scss';

/**
 * キービジュアル情報
 */
export interface KeyVisual {
  /** a タグの target 属性 */
  target?: '_blank';
  /** a タグの rel 属性 */
  rel?: 'noopener';
  /** 遷移先のurl */
  href: string;
  /** PCのキービジュアルとなる画像URL */
  image: string;
  /** 画像が表示できないときの代わりに表示される文言 */
  alt: string;
}

/**
 * ブランドランドパネル：キービジュアルのprops
 */
interface BrandPanelProps {
  keyVisual: KeyVisual;
}

export const BrandPanel: FC<BrandPanelProps> = ({ keyVisual }) => {
  return (
    <div className={styles.brandPanel__wrapperr}>
      <div className={styles.brandPanel}>
        <a
          className={styles.brandPanel__anchor}
          href={keyVisual.href}
          target={keyVisual.target}
          rel={keyVisual.rel}
        >
          <img
            className={styles.brandPanel__img}
            src={keyVisual.image}
            alt={keyVisual.alt}
            width={1920}
            height={323}
          />
        </a>
      </div>
    </div>
  );
};
