export const MATCH_JOB_LIST_Link =
  '/DodaFront/View/MatchJobList.action?usrclk=PC_logout_kyujinSearchArea_matchJobList';

export const MATCH_JOB_LIST_Link_WITH_NEW_ARRIVAL = `${MATCH_JOB_LIST_Link}&ds=1&from=top_new`;

export const JOB_HISTORY_LIST =
  '/kyujin/recommend/User_View_OcL.html?usrclk=PC_logout_kyujinSearchArea_jobHistoryListRecommend';

export const CONDITION_LIST =
  '/dcfront/login/login/?url=/dcfront/condition/conditionList/?usrclk=PC_logout_kyujinSearchArea_conditionList';

export const PREVIOUS_SEARCH_JOB_BASE_URL =
  '/DodaFront/View/JobSearchList.action';

export const PREVIOUS_SEARCH_JOB_BASE_COMMON_QUERIES =
  'usrclk=PC_logout_kyujinSearchArea_befSearchCondition&fromPcTop=true#history';
