import { FC } from 'react';
import styles from './index.module.scss';
import { SPECIALIST_SITE_LINK_LIST } from './constants';
import { formatSpecialLinkList } from './logics/formatSpecialLinkList';

/**
 * 専門サイトセクションprops
 */
interface SpecialistSiteSectionProps {
  /** ログイン状態 */
  isLoggedIn: boolean;
}

export const SpecialistSiteSection: FC<SpecialistSiteSectionProps> = ({
  isLoggedIn,
}) => {
  return (
    <ul className={styles.specialistSiteSection__linkList}>
      {formatSpecialLinkList(SPECIALIST_SITE_LINK_LIST, isLoggedIn).map(
        ({ id, url, jobType, jobTypeExamples }) => {
          return (
            <li key={id} className={styles.specialistSiteSection__link}>
              <a href={url}>
                <div className={styles.specialistSiteSection__jobType}>
                  {jobType}
                </div>
                <div className={styles.specialistSiteSection__jobTypeExamples}>
                  {jobTypeExamples}
                </div>
              </a>
            </li>
          );
        }
      )}
    </ul>
  );
};
