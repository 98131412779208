import Slider from 'react-slick';
import classNames from 'classnames';
import { ArrowLeft, ArrowRight } from '@doda/io-react/common';
import { FC } from 'react';
import styles from './index.module.scss';
import { type TodoIconType, TodoListCard } from '../TodoListCard';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { SLIDE_TO_SHOW, useTodoSlider } from './hooks';
import { UseMeasureTodoList } from '../../hooks/useMeasureTodoList';

/**
 * Todoの詳細
 */
export interface Todo {
  /** Todoタイトル */
  title: string;
  /** 遷移先URL */
  url: string;
  /** Todoのアイコンタイプ */
  iconType: TodoIconType;
  /** Todoの補足説明 */
  description?: string;
}

/**
 * ToDoリストコンポネントのProps
 */
interface Props {
  /** Todoの配列 */
  todoList: Todo[];
  /** Todoカードクリック時の計測関数 */
  measureTodoClick: UseMeasureTodoList['measureTodoClick'];
}

export const TodoList: FC<Props> = ({ todoList, measureTodoClick }) => {
  const { isSlider, isLeftEnd, isRightEnd, afterChange } = useTodoSlider(
    todoList.length
  );

  const handleClickTodo = (todo: Todo) => measureTodoClick(todo);

  if (isSlider) {
    return (
      <Slider
        className={styles.todoList__slideWrapper}
        infinite={false}
        speed={500}
        slidesToShow={SLIDE_TO_SHOW}
        slidesToScroll={1}
        prevArrow={<PrevArrow isLeftEnd={isLeftEnd} />}
        nextArrow={<NextArrow isRightEnd={isRightEnd} />}
        afterChange={afterChange}
      >
        {todoList.map((todo) => (
          <TodoListCard
            key={todo.url}
            url={todo.url}
            iconType={todo.iconType}
            title={todo.title}
            description={todo.description}
            handleClickTodo={() => handleClickTodo(todo)}
          />
        ))}
      </Slider>
    );
  }

  return (
    <ul className={styles.todoList__todoCardList}>
      {todoList.map((todo) => (
        <li key={todo.url}>
          <TodoListCard
            url={todo.url}
            iconType={todo.iconType}
            title={todo.title}
            description={todo.description}
            handleClickTodo={() => handleClickTodo(todo)}
          />
        </li>
      ))}
    </ul>
  );
};

const PrevArrow = (props) => {
  const { onClick, isLeftEnd } = props;
  const arrowColor = isLeftEnd ? 'gray' : 'blue';
  return (
    <button
      type="button"
      className={classNames(
        styles.todoList__sliderButton,
        styles['todoList__sliderButton--left'],
        isLeftEnd && styles['todoList__sliderButton--disabled']
      )}
      onClick={onClick}
    >
      <ArrowLeft color={arrowColor} size="XS" />
    </button>
  );
};

const NextArrow = (props) => {
  const { onClick, isRightEnd } = props;
  const arrowColor = isRightEnd ? 'gray' : 'blue';
  return (
    <button
      type="button"
      className={classNames(
        styles.todoList__sliderButton,
        styles['todoList__sliderButton--right'],
        isRightEnd && styles['todoList__sliderButton--disabled']
      )}
      onClick={onClick}
    >
      <ArrowRight color={arrowColor} size="XS" />
    </button>
  );
};
