import { FC, MouseEventHandler } from 'react';
import { SectionTitle } from '@doda/io-react/common';
import styles from './index.module.scss';
import { MoveToListLink } from '../MoveToListLink';
import { createOnAnchorClick } from '../../logics/createOnAnchorClick';

/**
 * 新着・注目のコンテンツバナーの情報
 */
export interface NewArrivalsFocusedContentsBanner {
  /** バナーに表示される画像URL */
  image: string;
  /** a タグの href 属性 */
  href: string;
  /** 画像が表示できないときの代わりに表示される文言 */
  alt: string;
  /** 外部ウィンドウで開くかどうかの設定 */
  externalWindow: boolean;
  /** バナー押下時の遷移先リンク */
  destinationUrl: string;
  /** a タグの target 属性 */
  target?: '_blank';
  /** a タグの rel 属性 */
  rel?: 'noopener';
}

/**
 * 新着・注目のコンテンツの情報
 */
export interface NewArrivalsFocusedContent {
  /** a タグの href 属性 */
  href: string;
  /** コンテンツ内容テキスト */
  title: string;
  /** コンテンツ押下時の遷移先リンク */
  destinationUrl: string;
  /** 外部ウィンドウで開くかどうかの設定 */
  externalWindow: boolean;
  /** a タグの target 属性 */
  target?: '_blank';
  /** a タグの rel 属性 */
  rel?: 'noopener';
}

/**
 * 新着・注目のコンテンツコンポーネントのprops
 */
export interface NewArrivalsFocusedContentsProps {
  /** 見出しテキスト */
  heading: string;
  /** イメージバナーの配列 */
  banners: NewArrivalsFocusedContentsBanner[];
  /** 新着・注目のコンテンツ情報の配列 */
  contents: NewArrivalsFocusedContent[];
  /** 一覧リンク a タグの href 属性 */
  listHref: string;
  /** 一覧リンクの表示テキスト */
  listText: string;
  /** 一覧リンク押下時の遷移先リンク */
  listDestinationUrl: string;
}

export const NewArrivalsFocusedContents: FC<
  NewArrivalsFocusedContentsProps
> = ({
  heading,
  contents,
  banners,
  listText,
  listHref,
  listDestinationUrl,
}) => {
  const onListLinkClick: MouseEventHandler<HTMLAnchorElement> = (event) => {
    event.preventDefault();
    window.location.href = listDestinationUrl;
  };

  return (
    <>
      <SectionTitle
        size="M"
        bgType="none"
        as="h3"
        className={styles.newArrivalsFocusedContents__title}
      >
        {heading}
      </SectionTitle>
      <ul className={styles.newArrivalsFocusedContents__banners}>
        {banners.map((banner) => {
          const onClick = createOnAnchorClick(
            banner.destinationUrl,
            banner.externalWindow
          );
          return (
            <li key={banner.href}>
              <a
                href={banner.href}
                target={banner.target}
                rel={banner.rel}
                onClick={onClick}
                className={styles.newArrivalsFocusedContents__bannerLink}
              >
                <img
                  src={banner.image}
                  alt={banner.alt}
                  className={styles.newArrivalsFocusedContents__banner}
                />
              </a>
            </li>
          );
        })}
      </ul>
      <ul className={styles.newArrivalsFocusedContents__list}>
        {contents.map((content) => {
          const onClick = createOnAnchorClick(
            content.destinationUrl,
            content.externalWindow
          );
          return (
            <li
              key={content.href}
              className={styles.newArrivalsFocusedContents__listItem}
            >
              <a
                href={content.href}
                className={styles.newArrivalsFocusedContents__anchor}
                target={content.target}
                rel={content.rel}
                onClick={onClick}
              >
                {content.title}
              </a>
            </li>
          );
        })}
      </ul>
      <MoveToListLink
        href={listHref}
        text={listText}
        onClick={onListLinkClick}
      />
    </>
  );
};
