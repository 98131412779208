import { TodoResponse } from '../../services/getTodoList';
import { Todo } from '../../components/TodoList';

export const EMPTY_TODO_LIST: Todo[] = [];

/**
 * やることリストAPIのレスポンスをコンポネントに必要な形に加工して返す関数
 * @param todoResponse - やることリストAPIのレスポンス
 * @returns TodoListAreaに必要なデータ
 */
export const formatTodoList = (
  todoResponse: PromiseSettledResult<TodoResponse>
) => {
  if (todoResponse.status === 'rejected') return EMPTY_TODO_LIST;

  const todoListMap = new Map<string, Todo>();

  if (
    todoResponse.value.counselingDateShow &&
    todoResponse.value.counselingDateString
  ) {
    todoListMap.set('counselingDate', {
      title: todoResponse.value.counselingDateString,
      url: '/dcfront/counseling/reservedCheck/?usrclk=PC_loginHome_todoArea_counselingConfirmed',
      iconType: 'counselingDate',
    });
  }

  if (todoResponse.value.interviewDateSettingShow) {
    todoListMap.set('interviewDateSettingShow', {
      title: '確定した選考日時を確認しましょう',
      url: '/dcfront/progress/progressTop/?usrclk=PC_loginHome_todoArea_interviewDateConfirmed',
      iconType: 'interviewDateSetting',
    });
  }

  if (todoResponse.value.dateSettingShow) {
    todoListMap.set('dateSettingShow', {
      title: '企業との面接希望日時を設定してください',
      url: '/dcfront/progress/progressTop/?usrclk=PC_loginHome_todoArea_interviewDateSelect',
      iconType: 'dateSetting',
    });
  }

  if (todoResponse.value.messageShow) {
    todoListMap.set('message', {
      title: '企業からメッセージが届いています',
      url: '/dcfront/progress/progressTop/?usrclk=PC_loginHome_todoArea_messageBox',
      iconType: 'message',
    });
  }

  if (todoResponse.value.resumeFileUploadShow) {
    todoListMap.set('resumeFileUpload', {
      title: '職務経歴書をアップロードしてください',
      url: '/dcfront/mypage/mypageResumeUpload/?usrclk=PC_loginHome_todoArea_profile',
      iconType: 'resumeFileUpload',
      description: '※メールで提出済みの方にも表示されています',
    });
  }

  if (todoResponse.value.counselingShow) {
    todoListMap.set('counseling', {
      title: 'カウンセリングを予約しましょう',
      url: '/dcfront/counseling/reserveType/?sid=counselingviaTop&usrclk=PC_loginHome_todoArea_counselingReserve',
      iconType: 'counseling',
    });
  }

  if (todoResponse.value.desiredConditionShow) {
    todoListMap.set('desiredCondition', {
      title: '希望条件を設定しましょう',
      url: '/dcfront/personal/personalRequirementsRegist/?usrclk=PC_loginHome_todoArea_desiredSetting',
      iconType: 'desiredCondition',
    });
  }

  return [...todoListMap.values()];
};
