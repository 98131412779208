import { CmsMemberInfo } from '@doda/common/src/interfaces/api/cmsMemberInfo';

type SuggestedJobType =
  | 'careerAdviserJob'
  | 'recruitmentProjectJob'
  | 'companyRecruiterJob'
  | 'partnerAgentJob';

/**
 * 紹介求人の件数テキストを生成して返す関数
 * @param type - 紹介求人のタイプ
 * @param cmsMemberInfo - 個人顧客情報
 * @returns 紹介求人の件数テキスト
 */
export const getSuggestedJobCount = (
  type: SuggestedJobType,
  cmsMemberInfo: CmsMemberInfo
) => {
  switch (type) {
    case 'careerAdviserJob':
      return cmsMemberInfo.wtsrfl === 2
        ? getJobCountString(cmsMemberInfo.referredjobCnt)
        : undefined;
    case 'recruitmentProjectJob':
      return cmsMemberInfo.wtsrfl === 2
        ? getJobCountString(cmsMemberInfo.saiyoprojectCnt)
        : undefined;
    case 'companyRecruiterJob':
      return cmsMemberInfo.wssrfl === 2
        ? getJobCountString(cmsMemberInfo.interviewOfferCnt)
        : undefined;
    case 'partnerAgentJob':
      return cmsMemberInfo.mapsAuthFl && cmsMemberInfo.whtrfl === 2
        ? getJobCountString(cmsMemberInfo.partnerAgentScoutCnt)
        : undefined;
    default:
      return undefined;
  }
};

const getJobCountString = (jobCount: number) => {
  if (jobCount > 999) return '999+';
  if (jobCount > 0) return `${jobCount}`;
  return undefined;
};
