import { CmsMemberInfo } from '@doda/common/src/interfaces/api/cmsMemberInfo';
import { getSuggestedCareerAdviserJob } from '../getSuggestedCareerAdviserJob';
import { getSuggestedRecruitProjectJob } from '../getSuggestedRecruitProjectJob';
import { getSuggestedCompanyRecruiterJob } from '../getSuggestedCompanyRecruiterJob';
import { getSuggestedPartnerAgentJob } from '../getSuggestedPartnerAgentJob';

/**
 * 個人顧客情報取得レスポンスをコンポーネントに必要な形へ加工
 * @param cmsMemberInfo - 個人顧客情報
 */
export const formatSuggestedJobs = (cmsMemberInfo: CmsMemberInfo) => {
  const hasPartnerAgentJob = cmsMemberInfo.whtrfl === 2;
  return {
    careerAdviserJob: getSuggestedCareerAdviserJob(cmsMemberInfo),
    recruitmentProjectJob: getSuggestedRecruitProjectJob(cmsMemberInfo),
    companyRecruiterJob: getSuggestedCompanyRecruiterJob(cmsMemberInfo),
    ...(hasPartnerAgentJob && {
      partnerAgentJob: getSuggestedPartnerAgentJob(cmsMemberInfo),
    }),
  };
};
