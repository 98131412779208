import { ComponentProps } from 'react';
import { useSaveBookmark } from '@doda/common/src/hooks/useSaveBookmark';

import { BookmarkParametersRequest } from '@doda/api-client/aspida/@types';
import { View } from './components/View';

type ViewProps = ComponentProps<typeof View>;

/**
 * 気になるボタンprops
 */
interface Props extends Omit<ViewProps, 'state'> {
  /** 応募済みか */
  isApplied?: boolean;
  /** 気になる登録済みか */
  isBookmarked?: boolean;
  /** 求人id */
  jid: string;
  /** リクエストボディ */
  requestBody: BookmarkParametersRequest;
  /** キャリアアドバイザー紹介求人かどうか */
  isCareerAdvisorJob?: boolean;
}

/**
 * 気になるボタンコンポーネント
 * @param {Props} props - コンポーネントに渡されるプロパティ
 * @returns {JSX.Element} 気になるボタンを表示する要素
 */
export const Favorite = ({
  isApplied,
  jid,
  requestBody,
  isBookmarked,
  onClickFavorite,
  onClickSaved,
  size,
  width,
  bookmarkListUrl,
  fadeTrigger = 'scroll',
  isCareerAdvisorJob = false,
}: Props) => {
  const { state, saveBookmark } = useSaveBookmark({
    jid,
    requestBody,
    isBookmarked,
    isCareerAdvisorJob,
  });

  return (
    <View
      state={isApplied ? 'applied' : state}
      onClickFavorite={(e) => {
        saveBookmark();
        onClickFavorite?.(e);
      }}
      onClickSaved={onClickSaved}
      size={size}
      width={width}
      bookmarkListUrl={bookmarkListUrl}
      fadeTrigger={fadeTrigger}
    />
  );
};
