import { CmsMemberInfo } from '@doda/common/src/interfaces/api/cmsMemberInfo';
import { getSuggestedJobCount } from '../getSuggestedJobCount';
import { SuggestedJob } from '../../components/SuggestedJobs';

const PARTNER_AGENT_JOB =
  '/dcfront/referredJob/mapsScoutList/?usrclk=PC_loginHome_menu_kyujinOffer_maps';

/**
 * 紹介求人のパートナーエージェント情報を生成して返す関数
 * @param cmsMemberInfo - 個人顧客情報
 * @returns 紹介求人のパートナーエージェント情報
 */
export const getSuggestedPartnerAgentJob = (cmsMemberInfo: CmsMemberInfo) => {
  const jobCount = getSuggestedJobCount('partnerAgentJob', cmsMemberInfo);
  const partnerAgentJob: SuggestedJob = { href: PARTNER_AGENT_JOB };

  if (jobCount) partnerAgentJob.jobCount = jobCount;

  return partnerAgentJob;
};
