import { IncomingHttpHeaders } from 'http';
import { appAxios } from '@doda/common/src/libs/axios/appAxios';
import { AxiosResponse } from 'axios';

/**
 * やることリストAPIのレスポンス
 */
export interface TodoResponse {
  /** 日時設定表示フラグ */
  dateSettingShow: boolean;
  /** 面談日時表示フラグ */
  interviewDateSettingShow: boolean;
  /** 企業からのメッセージ表示フラグ */
  messageShow: boolean;
  /** 職務経歴書アップロード表示フラグ */
  resumeFileUploadShow: boolean;
  /** カウンセリング予約表示フラグ */
  counselingShow: boolean;
  /** 希望条件設定表示フラグ */
  desiredConditionShow: boolean;
  /** カウンセリング予約日時表示フラグ */
  counselingDateShow: boolean;
  /** カウンセリング予約日時文言 */
  counselingDateString: string;
}

/**
 * やることリストを取得する関数
 * @param headers - リクエストヘッダー
 * @returns やることリストAPIのレスポンス
 */
export const getTodoList = async (
  headers?: IncomingHttpHeaders
): Promise<TodoResponse> => {
  return appAxios({
    method: 'get',
    url: '/dcfront/cms/cmsMemberInfo/getProgressTop',
    headers,
  }).then((response: AxiosResponse<TodoResponse>) => {
    return response.data;
  });
};
