import { useEffect } from 'react';

/**
 * モーダルの自動非表示フック
 *
 * @param showModal モーダルが表示されているかどうかのフラグ
 * @param hideModal モーダルを非表示にする関数
 */
const useAutoHideModal = (showModal: boolean, hideModal: () => void) => {
  useEffect(() => {
    if (showModal) {
      const timer = setTimeout(() => {
        hideModal();
      }, 45000);

      return () => clearTimeout(timer);
    }

    return () => {};
  }, [showModal, hideModal]);
};

export default useAutoHideModal;
