import { FC, useState } from 'react';
import {
  BasicModal,
  Text,
  Balloon,
  Heart,
  Box,
  OutlineTag,
  Button,
} from '@doda/io-react/common';
import styles from './index.module.scss';

/**
 * 応募リフトアップコンポーネントのProps
 */
export interface ApplicationUpliftProps {
  // 応募リフトアップモーダルを表示するかどうか
  isShowModal: boolean;
  // 詳細へのリンクURL
  detailUrl: string;
  // 面接確約フラグ
  isInterview: boolean;
  // 応募歓迎フラグ
  isBalloon: boolean;
  // 一覧へのリンクURL
  listUrl: string;
  // 求人詳細
  jobDetail: JobDetail;
}

/**
 * 求人詳細のProps
 */
interface JobDetail {
  // 求人のタイトル
  title: string;
  // 会社（企業)名
  companyName: string;
}
export const ApplicationUplift: FC<ApplicationUpliftProps> = ({
  isShowModal,
  detailUrl,
  isInterview,
  isBalloon,
  listUrl,
  jobDetail,
}) => {
  const [showModal, setShowModal] = useState(isShowModal);
  const closeModal = () => {
    setShowModal(false);
  };
  if (!showModal) return null;
  return (
    <>
      <button
        type="button"
        onClick={closeModal}
        className={styles.applicationUplift__overlay}
      />
      <BasicModal
        onClickClose={closeModal}
        className={styles.applicationUplift}
        show={showModal}
        width={450}
      >
        <div className={styles.applicationUplift__wrapper}>
          <div>
            <Text
              weight="bold"
              size="14--articlePC"
              color="black_pc"
              className={styles.applicationUplift__title}
            >
              気になるリストの求人へ応募しませんか
            </Text>
            <Text
              size="12"
              color="black_pc"
              className={styles.applicationUplift__text}
            >
              転職活動開始2～3週間目から応募を始めるのがおすすめです。
              <br />
              まずは応募して企業に話を聞いてみましょう。
            </Text>
          </div>
          <div>
            {isBalloon && (
              <Balloon
                size="M"
                color="turquoise"
                shadow="none"
                className={styles.applicationUplift__balloon}
              >
                <Heart size="M" color="yellow" />
                <span className={styles.applicationUplift__balloonText}>
                  <Text size="16" color="white" weight="bold">
                    マッチング成立！
                  </Text>
                  <Text size="12" color="white" weight="bold">
                    応募をお待ちしています。
                  </Text>
                </span>
              </Balloon>
            )}
            <a href={detailUrl}>
              <Box
                color="white"
                boxShadow="black"
                radius="4"
                className={styles.applicationUplift__jobDetail}
              >
                <Text
                  color="blue"
                  weight="bold"
                  size="16"
                  className={styles.applicationUplift__jobDetailTitle}
                >
                  {jobDetail.title}
                </Text>
                <Text
                  size="12"
                  className={styles.applicationUplift__jobDetailCompanyName}
                >
                  {jobDetail.companyName}
                </Text>
                {isInterview && (
                  <OutlineTag
                    color="orange"
                    className={styles.applicationUplift__jobDetailTag}
                  >
                    面接確約
                  </OutlineTag>
                )}
              </Box>
            </a>
          </div>
          <Button
            as="a"
            size="M"
            variant="outline_gray"
            href={listUrl}
            className={styles.applicationUplift__button}
          >
            気になるリストの求人をすべて見る
          </Button>
        </div>
      </BasicModal>
    </>
  );
};
