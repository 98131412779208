import { FC } from 'react';
import { RoundedTag, ArrowRight, Text } from '@doda/io-react/common';
import styles from './index.module.scss';

/**
 * 検索メニュー項目詳細
 */
interface SearchMenuProps {
  // 項目名
  name: string;
  // 遷移先URL
  url?: string;
  // 求人件数
  jobCount?: number;
  // 「新着あり」タグの表示有無
  hasNewArrival?: boolean;
  // 希望条件テキスト表示有無
  showDesiredCondition?: boolean;
  // 希望条件テキスト
  desiredCondition?: string;
  // 希望条件モーダル
  onClick?: React.MouseEventHandler<HTMLElement>;
}

export const SearchMenuLogOutParts: FC<SearchMenuProps> = ({
  name,
  url,
  jobCount,
  hasNewArrival,
  showDesiredCondition,
  desiredCondition,
  onClick,
}) => {
  const textAreaStyle = hasNewArrival ? `withTag` : `withoutTag`;

  return (
    <a href={url} onClick={onClick} className={styles.searchMenu__anchorLink}>
      <div className={styles[`searchMenu__listTextArea--${textAreaStyle}`]}>
        <Text color="blue" weight="bold" size="12">
          {name}
          {jobCount !== undefined && (
            <Text color="blue" size="10" as="span">
              ({jobCount.toLocaleString()})
            </Text>
          )}
        </Text>
        {showDesiredCondition && (
          <Text
            color="gray"
            size="11"
            className={styles.searchMenu__desiredConditionText}
          >
            {desiredCondition || '希望条件を設定してください'}
          </Text>
        )}
      </div>
      {hasNewArrival && <RoundedTag color="yellow">新着あり</RoundedTag>}
      <ArrowRight size="XS" color="gray" />
    </a>
  );
};
