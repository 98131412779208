import React, { useState, useEffect } from 'react';
import { SuggestedJobs, SuggestedJobsProps } from '../index';
import { getCmsMemberInfo } from '../../../services/getCmsMemberInfo';
import { formatSuggestedJobs } from '../../../logics/formatSuggestedJobs';

/**
 * 紹介求人のコンテナーコンポーネント
 * @param props - SuggestedJobsProps
 */
export const SuggestedJobsContainer = (props: SuggestedJobsProps) => {
  const [data, setData] = useState(props);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getCmsMemberInfo();
        const result = formatSuggestedJobs(response);
        setData(result);
      } catch (error) {
        /* empty */
      }
    };

    fetchData();
  }, []);

  return (
    <SuggestedJobs
      careerAdviserJob={data.careerAdviserJob}
      recruitmentProjectJob={data.recruitmentProjectJob}
      companyRecruiterJob={data.companyRecruiterJob}
      partnerAgentJob={data.partnerAgentJob}
    />
  );
};

export default SuggestedJobsContainer;
