import React, { FC, Fragment, MouseEvent } from 'react';
import styles from './index.module.scss';
import { WorkLocationForDisplay } from '../../types';

/**
 * 勤務地一覧Props
 */
interface LocationListProps {
  /* 勤務地一覧 */
  locationList: WorkLocationForDisplay[];
}

export const LocationList: FC<LocationListProps> = ({ locationList }) => {
  const handleClick = (
    e: MouseEvent<HTMLAnchorElement>,
    locationHref: string
  ) => {
    e.preventDefault();
    window.location.href = locationHref;
  };

  return (
    <ul className={styles.locationList__locationList}>
      {locationList.map((area) => {
        return (
          <li key={area.url} className={styles.locationList__location}>
            <a
              href={area.url}
              className={styles.locationList__area}
              onClick={(e) => handleClick(e, area.locationHref)}
            >
              {area.name}
            </a>
            <ul className={styles.locationList__prefectureList}>
              {area.prefectures.map((prefecture) => {
                const hasCities = prefecture.designatedCities.length > 0;

                return (
                  <li
                    key={prefecture.url}
                    className={styles.locationList__prefecture}
                  >
                    <a
                      href={prefecture.url}
                      onClick={(e) => handleClick(e, prefecture.locationHref)}
                    >
                      {prefecture.name}
                    </a>
                    {hasCities && <span>（</span>}
                    {prefecture.designatedCities.map((city, i) => {
                      const isLastItem =
                        i === prefecture.designatedCities.length - 1;

                      return (
                        <Fragment key={city.url}>
                          <a
                            href={city.url}
                            onClick={(e) => handleClick(e, city.locationHref)}
                          >
                            {city.name}
                          </a>
                          {!isLastItem && <span>・</span>}
                        </Fragment>
                      );
                    })}
                    {hasCities && <span>）</span>}
                  </li>
                );
              })}
            </ul>
          </li>
        );
      })}
    </ul>
  );
};
