import { MouseEventHandler } from 'react';
import {
  PREVIOUS_SEARCH_JOB_BASE_URL,
  PREVIOUS_SEARCH_JOB_BASE_COMMON_QUERIES,
} from '../../constants';

type CreateOnPreviousSearchJobClick = (
  hasNewArrival: boolean,
  previousSearchCondition: string | undefined
) => MouseEventHandler<HTMLAnchorElement>;

/**
 * 前回の検索条件のクリックイベントハンドラーを返す関数
 * @param hasNewArrival 新着求人が存在するか
 * @param previousSearchCondition 前回の検索条件パラメータ
 * @returns 前回の検索条件クリックイベントハンドラー
 */
export const createOnPreviousSearchJobClick: CreateOnPreviousSearchJobClick = (
  hasNewArrival,
  previousSearchCondition
) => {
  const searchCondition = previousSearchCondition
    ? `${previousSearchCondition}&`
    : '';
  const newArrivalQueries = hasNewArrival ? 'from=top_new&ds=1&' : '';
  const previousSearchJobUrl = `${PREVIOUS_SEARCH_JOB_BASE_URL}?${searchCondition}${newArrivalQueries}${PREVIOUS_SEARCH_JOB_BASE_COMMON_QUERIES}`;

  const previousSearchJobClick: MouseEventHandler<HTMLAnchorElement> = (
    event
  ) => {
    event.preventDefault();
    window.location.href = previousSearchJobUrl;
  };

  return previousSearchJobClick;
};
