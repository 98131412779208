import { FC } from 'react';
import styles from './index.module.scss';

export const SiteDescription: FC = () => {
  return (
    <p className={styles.siteDescription}>
      パーソルキャリア株式会社の転職サイトdoda。
      <br />
      正社員のお仕事を中心に、さまざまなサービスを展開しています。
      <br />
      業種・職種ごとの専任のキャリアアドバイザーがマッチングを行うdodaエージェントサービスでは、転職相談から求人紹介、面接対策や応募書類作成をお手伝いしています。
      <br />
      また、サイトに掲載されている求人情報の数は国内最大級。職種、勤務地、こだわり条件などあなたの希望条件にマッチした求人検索も可能です。
      <br />
      dodaの転職サービスは、求人数、バリエーションともに豊富に揃え、あなたの転職を成功に導きます。
    </p>
  );
};
