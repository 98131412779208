import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { appAxios } from '@doda/common/src/libs/axios/appAxios';
import { CmsMemberInfo } from '@doda/common/src/interfaces/api/cmsMemberInfo';
import { CMSMENBERINFO_KEYS } from '@doda/common/src/constants/cmsMemberInfoApiKey';
import { setBookmark } from '..';

/**
 * ClientからのMemberInfo取得
 */
export const getCmsMemberInfoForBookmarkCount = createAsyncThunk<
  void,
  undefined,
  { rejectValue: Record<string, never> }
>('getCmsMemberInfoForClient', async (_, thunkAPI) => {
  return appAxios({
    url: '/dcfront/cms/cmsMemberInfo/',
    params: {
      keys: CMSMENBERINFO_KEYS.bookmark,
    },
  })
    .then(async ({ data }: AxiosResponse<CmsMemberInfo>) => {
      thunkAPI.dispatch(setBookmark(data.bookmarkCnt));
    })
    .catch(() => {
      return thunkAPI.rejectWithValue({});
    });
});
