import { FC, useState } from 'react';
import Image from 'next/image';
import { Button, Text } from '@doda/io-react/common';
import { newLineCodeDelete } from 'logics/newLineCodeDelete';
import styles from './index.module.scss';
import { getOfferText } from './Logic/getOfferText';
import { getImage } from './Logic/getImage';
import { getActionButton } from './Logic/getActionButton';
import { postScoutPopupInformation } from './Logic/postScoutPopupInformation';
import { getTitle } from './Logic/getTitle';
import { NullableString, NullableNumber } from './constants';
import useAutoHideModal from './hooks/usePremiumOffer';

/**
 * プレミアムオファーコンポーネントのProps
 */
export interface PremiumOfferProps {
  // プレミアムオファーモーダルを表示するかどうか
  isShowModal: boolean;
  // スカウト理由
  scoutReason: NullableString;
  // 会社名
  companyName: NullableString;
  // 採用担当者画像
  recruiterImage: NullableString;
  // 採用担当者画像コンテントタイプ
  contentType: NullableString;
  // メッセージタイトル
  messageTitle: NullableString;
  // 職種名
  occupationName: NullableString;
  // メッセージ本文
  message: NullableString;
  // 案件ID
  caseId: NullableNumber;
  // スカウトリストID
  scoutListId: NullableNumber;
  // 状況確認ステータス
  statusCheck: NullableNumber;
  // 受信先情報ID
  recipientInformationId: NullableNumber;
  // メッセージID
  messageId: NullableNumber;
  // ポップアップ用表示制御ID
  displayControlId: NullableNumber;
}

export const PremiumOffer: FC<PremiumOfferProps> = ({
  isShowModal,
  scoutReason,
  companyName,
  recruiterImage,
  contentType,
  messageTitle,
  occupationName,
  message,
  caseId,
  scoutListId,
  statusCheck,
  recipientInformationId,
  messageId,
  displayControlId,
}) => {
  const [showModal, setShowModal] = useState(isShowModal);
  useAutoHideModal(showModal, () => setShowModal(false));

  const offer = getOfferText(scoutReason);
  const title = getTitle(companyName, offer);
  const image = getImage(recruiterImage, contentType);
  const actionButton = getActionButton(
    caseId,
    scoutListId,
    statusCheck,
    recipientInformationId,
    messageId
  );
  const generateMassage = message && newLineCodeDelete(message);
  const closeModal = () => {
    setShowModal(false);
  };
  const onClickReprocessing = () => {
    if (displayControlId) {
      postScoutPopupInformation(displayControlId);
      setShowModal(false);
    }
  };

  if (!showModal) return null;

  return (
    <div className={styles.premiumOffer}>
      <div className={styles.premiumOffer__ModalTitleWrapper}>
        <div className={styles.premiumOffer__ModalTitle}>{title}</div>
        <button
          className={styles.premiumOffer__ModalCloseButton}
          type="button"
          aria-label="close"
          onClick={closeModal}
        />
      </div>
      <div className={styles.premiumOffer__wrapper}>
        <div className={styles.premiumOffer__offerDetail}>
          <Image src={image} height={108} width={81} alt="担当者写真" />
          <div className={styles.premiumOffer__offerDetailWrapper}>
            {messageTitle && (
              <Text
                size="14--articlePC"
                color="black_pc"
                className={styles.premiumOffer__offerDetailTitle}
              >
                {messageTitle}
              </Text>
            )}
            {occupationName && (
              <Text
                size="14--articlePC"
                color="black_pc"
                className={styles.premiumOffer__offerDetailOccupationName}
              >
                {occupationName}
              </Text>
            )}
          </div>
        </div>
        {message && (
          <Text
            size="12"
            color="black_pc"
            className={styles.premiumOffer__message}
          >
            {generateMassage}
          </Text>
        )}
        <div className={styles.premiumOffer__buttonWrapper}>
          <Button
            as="a"
            size="M"
            variant="green"
            className={styles.premiumOffer__button}
            href={actionButton.href}
          >
            {actionButton.text}
          </Button>
          <Button
            as="a"
            size="M"
            variant="outline_gray"
            className={styles.premiumOffer__button}
            onClick={onClickReprocessing}
          >
            次回ログイン時に再度表示
          </Button>
        </div>
      </div>
    </div>
  );
};
