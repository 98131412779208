import { MouseEventHandler } from 'react';

type CreateOnAnchorClick = (
  destinationUrl: string,
  externalWindow: boolean
) => MouseEventHandler<HTMLAnchorElement>;

/**
 * aタグのhrefと異なる遷移先に移動させるクリックイベントハンドラーを返す関数
 * @param destinationUrl - 実際の遷移先のURL
 * @param externalWindow - 外部ウィンドウで開くかどうかのフラグ
 * @returns aタグのクリックイベントハンドラー
 */
export const createOnAnchorClick: CreateOnAnchorClick =
  (destinationUrl, externalWindow) => (event) => {
    event.preventDefault();

    if (externalWindow) window.open(destinationUrl);
    else window.location.href = destinationUrl;
  };
