import React, { useState, useEffect } from 'react';
import type { Todo } from '../../TodoList';
import {
  EMPTY_TODO_LIST,
  formatTodoList,
} from '../../../logics/formatTodoList';
import { ToDoListArea } from '../index';
import { getTodoList } from '../../../services/getTodoList';

/**
 * ToDoリストのコンテナコンポーネント
 */
export const ToDoListAreaContainer = () => {
  const [data, setData] = useState<Todo[]>(EMPTY_TODO_LIST);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // MEMO: API側で速度改善された場合、再びSSRへ処理を移行させる可能性があることから、formatTodoListが要求している型に合わせている
        const [response] = await Promise.allSettled([getTodoList()]);
        const result = formatTodoList(response);
        setData(result);
        setLoading(false);
      } catch (error) {
        /* empty */
      }
    };

    fetchData();
  }, []);

  return <ToDoListArea todoList={data} isLoading={loading} />;
};

export default ToDoListAreaContainer;
