import { useSetModal } from 'pages/index.page';

/**
 * 希望条件変更モーダルの表示用のフック
 * @param isDesiredCond Cookieに[desired_cond]が存在するか
 * @returns 希望にマッチした求人項目のデータ
 */
export const useMachJob = (isDesiredCond: boolean) => {
  const setModal = useSetModal();

  const handleClickChange: React.MouseEventHandler<HTMLButtonElement> = () => {
    setModal(true);
  };

  const matchJobClick = !isDesiredCond ? handleClickChange : () => {};

  return { matchJobClick };
};
