import { CmsMemberInfo } from '@doda/common/src/interfaces/api/cmsMemberInfo';
import { ValueOf } from 'next/constants';
import { getSuggestedJobCount } from '../getSuggestedJobCount';
import { SuggestedJob } from '../../components/SuggestedJobs';

const USED_HREF =
  '/dcfront/referredJob/interviewOfferList/?usrclk=PC_loginMember_memberFunctionArea_kyujinOffer_clientOffer&sid=PC_ScoutBox_clientOffer';
const UNUSED_HREF =
  '/scout/?usrclk=PC_loginMember_memberFunctionArea_kyujinOffer_clientOffer';

const UNUSED = 1;

const UNUSED_STATE_MAP = {
  1: '未利用',
  3: '停止中',
} as const;

type UnusedState = ValueOf<typeof UNUSED_STATE_MAP> | undefined;

/**
 * 紹介求人の企業採用担当者情報を生成して返す関数
 * @param cmsMemberInfo - 個人顧客情報
 * @returns 紹介求人の企業採用担当者情報
 */
export const getSuggestedCompanyRecruiterJob = (
  cmsMemberInfo: CmsMemberInfo
) => {
  const hasBeenUsed = cmsMemberInfo.wssrfl !== UNUSED;

  const href = hasBeenUsed ? USED_HREF : UNUSED_HREF;
  const jobCount = getSuggestedJobCount('companyRecruiterJob', cmsMemberInfo);
  const usageStatus: UnusedState = UNUSED_STATE_MAP[cmsMemberInfo.wssrfl];

  const recruitmentProjectJob: SuggestedJob = { href };

  if (jobCount) recruitmentProjectJob.jobCount = jobCount;
  if (usageStatus) recruitmentProjectJob.usageStatus = usageStatus;

  return recruitmentProjectJob;
};
