import { Area, DesignatedCity, Prefecture } from '../types';

/**
 * 勤務地用の地域リスト
 */
export const AREA_LIST: Area[] = [
  {
    id: '1',
    name: '北海道',
    url: '/area/hokkaido/',
    queryParam: 'hokkaido',
  },
  {
    id: '2',
    name: '東北',
    url: '/area/tohoku/',
    queryParam: 'touhoku',
  },
  {
    id: '3',
    name: '関東',
    url: '/area/kanto/',
    queryParam: 'kantou',
  },
  {
    id: '4',
    name: '東海',
    url: '/area/tokai/',
    queryParam: 'toukaiArea',
  },
  {
    id: '5',
    name: '北信越',
    url: '/area/hokushin/',
    queryParam: 'hokushinetsu',
  },
  {
    id: '6',
    name: '関西',
    url: '/area/kansai/',
    queryParam: 'kansaiArea',
  },
  {
    id: '7',
    name: '中国・四国',
    url: '/area/chugokushikoku/',
    queryParam: 'chugokushikoku',
  },
  {
    id: '8',
    name: '九州・沖縄',
    url: '/area/kyushu/',
    queryParam: 'kyushuokinawa',
  },
  {
    id: '9',
    name: '海外',
    url: '/area/kaigai/',
    queryParam: 'kaigai',
  },
];

/**
 * 勤務地用の都道府県リスト
 */
export const PREFECTURE_LIST: Prefecture[] = [
  {
    id: '1',
    name: '北海道',
    queryParam: 'hokkaido',
    areaId: '1',
  },
  {
    id: '2',
    name: '青森',
    queryParam: 'aomori',
    areaId: '2',
  },
  {
    id: '3',
    name: '岩手',
    queryParam: 'iwate',
    areaId: '2',
  },
  {
    id: '4',
    name: '宮城',
    queryParam: 'miyagi',
    areaId: '2',
  },
  {
    id: '5',
    name: '秋田',
    queryParam: 'akita',
    areaId: '2',
  },
  {
    id: '6',
    name: '山形',
    queryParam: 'yamagata',
    areaId: '2',
  },
  {
    id: '7',
    name: '福島',
    queryParam: 'fukushima',
    areaId: '2',
  },
  {
    id: '8',
    name: '茨城',
    queryParam: 'ibaraki',
    areaId: '3',
  },
  {
    id: '9',
    name: '栃木',
    queryParam: 'tochigi',
    areaId: '3',
  },
  {
    id: '10',
    name: '群馬',
    queryParam: 'gunma',
    areaId: '3',
  },
  {
    id: '11',
    name: '埼玉',
    queryParam: 'saitama',
    areaId: '3',
  },
  {
    id: '12',
    name: '千葉',
    queryParam: 'chiba',
    areaId: '3',
  },
  {
    id: '13',
    name: '東京',
    queryParam: 'tokyo',
    areaId: '3',
  },
  {
    id: '14',
    name: '神奈川',
    queryParam: 'kanagawa',
    areaId: '3',
  },
  {
    id: '15',
    name: '新潟',
    queryParam: 'niigata',
    areaId: '5',
  },
  {
    id: '16',
    name: '富山',
    queryParam: 'toyama',
    areaId: '5',
  },
  {
    id: '17',
    name: '石川',
    queryParam: 'ishikawa',
    areaId: '5',
  },
  {
    id: '18',
    name: '福井',
    queryParam: 'fukui',
    areaId: '5',
  },
  {
    id: '19',
    name: '山梨',
    queryParam: 'yamanashi',
    areaId: '3',
  },
  {
    id: '20',
    name: '長野',
    queryParam: 'nagano',
    areaId: '5',
  },
  {
    id: '21',
    name: '岐阜',
    queryParam: 'gifu',
    areaId: '4',
  },
  {
    id: '22',
    name: '静岡',
    queryParam: 'shizuoka',
    areaId: '4',
  },
  {
    id: '23',
    name: '愛知',
    queryParam: 'aichi',
    areaId: '4',
  },
  {
    id: '24',
    name: '三重',
    queryParam: 'mie',
    areaId: '4',
  },
  {
    id: '25',
    name: '滋賀',
    queryParam: 'shiga',
    areaId: '6',
  },
  {
    id: '26',
    name: '京都',
    queryParam: 'kyoto',
    areaId: '6',
  },
  {
    id: '27',
    name: '大阪',
    queryParam: 'osaka',
    areaId: '6',
  },
  {
    id: '28',
    name: '兵庫',
    queryParam: 'hyogo',
    areaId: '6',
  },
  {
    id: '29',
    name: '奈良',
    queryParam: 'nara',
    areaId: '6',
  },
  {
    id: '30',
    name: '和歌山',
    queryParam: 'wakayama',
    areaId: '6',
  },
  {
    id: '31',
    name: '鳥取',
    queryParam: 'tottori',
    areaId: '7',
  },
  {
    id: '32',
    name: '島根',
    queryParam: 'shimane',
    areaId: '7',
  },
  {
    id: '33',
    name: '岡山',
    queryParam: 'okayama',
    areaId: '7',
  },
  {
    id: '34',
    name: '広島',
    queryParam: 'hiroshima',
    areaId: '7',
  },
  {
    id: '35',
    name: '山口',
    queryParam: 'yamaguchi',
    areaId: '7',
  },
  {
    id: '36',
    name: '徳島',
    queryParam: 'tokushima',
    areaId: '7',
  },
  {
    id: '37',
    name: '香川',
    queryParam: 'kagawa',
    areaId: '7',
  },
  {
    id: '38',
    name: '愛媛',
    queryParam: 'ehime',
    areaId: '7',
  },
  {
    id: '39',
    name: '高知',
    queryParam: 'kouchi',
    areaId: '7',
  },
  {
    id: '40',
    name: '福岡',
    queryParam: 'fukuoka',
    areaId: '8',
  },
  {
    id: '41',
    name: '佐賀',
    queryParam: 'saga',
    areaId: '8',
  },
  {
    id: '42',
    name: '長崎',
    queryParam: 'nagasaki',
    areaId: '8',
  },
  {
    id: '43',
    name: '熊本',
    queryParam: 'kumamoto',
    areaId: '8',
  },
  {
    id: '44',
    name: '大分',
    queryParam: 'oita',
    areaId: '8',
  },
  {
    id: '45',
    name: '宮崎',
    queryParam: 'miyazaki',
    areaId: '8',
  },
  {
    id: '46',
    name: '鹿児島',
    queryParam: 'kagoshima',
    areaId: '8',
  },
  {
    id: '47',
    name: '沖縄',
    queryParam: 'okinawa',
    areaId: '8',
  },
  {
    id: '48',
    name: '海外',
    queryParam: 'kaigai',
    areaId: '9',
  },
];

/**
 * 勤務地用の市区町村(政令指定都市)リスト
 */
export const DESIGNATED_CITY_LIST: DesignatedCity[] = [
  {
    id: '011002',
    name: '札幌市',
    queryParam: 'sapporo',
    prefectureId: '1', // 北海道
  },
  {
    id: '041009',
    name: '仙台市',
    queryParam: 'sendai',
    prefectureId: '4', // 宮城
  },
  {
    id: '141003',
    name: '横浜市',
    queryParam: 'yokohama',
    prefectureId: '14', // 神奈川
  },
  {
    id: '221007',
    name: '静岡市',
    queryParam: 'shizuoka',
    prefectureId: '22', // 静岡
  },
  {
    id: '221309',
    name: '浜松市',
    queryParam: 'hamamatsu',
    prefectureId: '22', // 静岡
  },
  {
    id: '231002',
    name: '名古屋市',
    queryParam: 'nagoya',
    prefectureId: '23', // 愛知
  },
  {
    id: '261009',
    name: '京都市',
    queryParam: 'kyoto',
    prefectureId: '26', // 京都
  },
  {
    id: '271004',
    name: '大阪市',
    queryParam: 'osaka',
    prefectureId: '27', // 大阪
  },
  {
    id: '281000',
    name: '神戸市',
    queryParam: 'kobe',
    prefectureId: '28', // 兵庫
  },
  {
    id: '341002',
    name: '広島市',
    queryParam: 'hiroshima',
    prefectureId: '34', // 広島
  },
  {
    id: '401307',
    name: '福岡市',
    queryParam: 'hukuoka',
    prefectureId: '40', // 福岡
  },
];

/**
 * 表示する勤務地の列を分けるインデックス
 */
export const SLICE_INDEX = 5;
