import { getUsrclkQuery } from 'pages/DodaFront/View/Top/logics/getUsrclkQuery';
import { WorkLocationForDisplay } from '../../components/WorkLocationSection/types';
import {
  AREA_LIST,
  PREFECTURE_LIST,
  DESIGNATED_CITY_LIST,
} from '../../components/WorkLocationSection/constants';
import { addQueriesToUrl } from '../addQueriesToUrl';

/**
 * 勤務地から探す表示用データ作成関数
 * @param isLoggedIn
 * @returns 勤務地から探すに必要なデータ
 */
export const getWorkLocationList = (
  isLoggedIn: boolean
): WorkLocationForDisplay[] =>
  AREA_LIST.map((area) => {
    const areaQueryParam = getUsrclkQuery(
      isLoggedIn,
      `kyujinSearchLocationArea_area_${area.queryParam}`
    );
    const areaURLWithQuery = addQueriesToUrl(area.url, areaQueryParam);

    const prefectures = PREFECTURE_LIST.filter(
      (prefecture) => prefecture.areaId === area.id
    ).map((prefecture) => {
      const prefectureQueryParam = getUsrclkQuery(
        isLoggedIn,
        `kyujinSearchLocationArea_prefecture_${prefecture.queryParam}`
      );
      const prefectureURL =
        prefecture.id === '48'
          ? `/DodaFront/View/JobSearchList/j_ar__99/-preBtn__1/`
          : `/DodaFront/View/JobSearchList/j_pr__${prefecture.id}/-preBtn__1/`;

      const prefectureURLWithQuery = addQueriesToUrl(
        prefectureURL,
        prefectureQueryParam
      );

      const designatedCities = DESIGNATED_CITY_LIST.filter(
        (city) => city.prefectureId === prefecture.id
      ).map((city) => {
        const cityQueryParam = getUsrclkQuery(
          isLoggedIn,
          `kyujinSearchLocationArea_city_${city.queryParam}`
        );
        const cityURL = `/DodaFront/View/JobSearchList/j_ci__${city.id}/-preBtn__1/`;
        const cityURLWithQuery = addQueriesToUrl(cityURL, cityQueryParam);

        return {
          name: city.name,
          url: cityURL,
          locationHref: cityURLWithQuery,
        };
      });

      return {
        name: prefecture.name,
        url: prefectureURL,
        locationHref: prefectureURLWithQuery,
        designatedCities,
      };
    });

    return {
      name: area.name,
      url: area.url,
      locationHref: areaURLWithQuery,
      prefectures,
    };
  });
