/**
 * params オブジェクトを url クエリパラメータの文字列に変換して返す関数
 * @param params - リンククリック時に付与されるクエリパラメータ
 * @returns url クエリパラメータの文字列
 */
export const getSearchParams = (params: Record<string, string>) => {
  const searchParams = new URLSearchParams();

  Object.entries(params).forEach(([key, value]) => {
    searchParams.append(key, value);
  });

  return searchParams.toString();
};
