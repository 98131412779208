import { Result as RecommendJob } from '@doda/common/src/interfaces/api/recommendPanel';

/**
 * 指定された範囲の要素のitemNoをカンマで繋いだ文字列を返す
 * @param {Array<{ itemNo: string }>} items itemNoを持つオブジェクトの配列
 * @param {number} startIndex 範囲の開始インデックス
 * @param {number} endIndex 範囲の終了インデックス
 * @returns {string} itemNoをカンマで繋いだ文字列
 */
export const getItemNoStringInRange = (
  items: Pick<RecommendJob, 'itemNo'>[],
  startIndex: number,
  endIndex: number
): string => {
  if (startIndex < 0 || startIndex >= items.length || startIndex > endIndex) {
    throw new Error('インデックスが無効です。');
  }

  // endIndex が範囲外の場合、最後のインデックスに設定
  const validEndIndex = endIndex >= items.length ? items.length - 1 : endIndex;

  return items
    .slice(startIndex, validEndIndex + 1)
    .map((item) => item.itemNo)
    .join(',');
};
