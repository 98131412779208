import { FC } from 'react';
import styles from './index.module.scss';
import { SideNavBottomLinkImageBanner } from './types';
/**
 *sideNavBottomLinkImageBannerを配列にしたProps
 */
interface SideNavBottomLinkBannerProps {
  rightSideNavigationBottomBanners: SideNavBottomLinkImageBanner[];
}

export const SideNavBottomLinkBanner: FC<SideNavBottomLinkBannerProps> = ({
  rightSideNavigationBottomBanners,
}) => {
  return (
    <div className={styles.sideNavBottomLinkBanner}>
      {rightSideNavigationBottomBanners.map((banner) => (
        <a
          key={banner.href}
          href={banner.href}
          target={banner.target}
          rel={banner.rel}
          className={styles.sideNavBottomLinkBanner__link}
        >
          <img
            className={styles.sideNavBottomLinkBanner__image}
            src={banner.image}
            alt={banner.alt}
          />
        </a>
      ))}
    </div>
  );
};
