import React, { FC } from 'react';
import styles from './index.module.scss';

import { SLICE_INDEX } from './constants';
import { LocationList } from './components/LocationList';
import { getWorkLocationList } from '../../logics/getWorkLocationList';

/**
 * 勤務地一覧セクションprops
 */
interface WorkLocationSectionProps {
  /* ログイン状態 */
  isLoggedIn: boolean;
}

export const WorkLocationSection: FC<WorkLocationSectionProps> = ({
  isLoggedIn,
}) => {
  const workLocationList = getWorkLocationList(isLoggedIn);
  const locationLeft = workLocationList.slice(0, SLICE_INDEX);
  const locationRight = workLocationList.slice(SLICE_INDEX);
  return (
    <section className={styles.workLocationSection}>
      <h2 className={styles.workLocationSection__heading}>勤務地から探す</h2>
      <div className={styles.workLocationSection__locationListRow}>
        <LocationList locationList={locationLeft} />
        <LocationList locationList={locationRight} />
      </div>
    </section>
  );
};
