import React, { FC, useState } from 'react';
import { ArrowDown } from '@doda/io-react/common';
import classNames from 'classnames';

import styles from './index.module.scss';
import { QuickSearchType } from '../../types';
import {
  INCOMES,
  LOCATIONS,
  OCCUPATIONS,
  QUICK_SEARCH_MAP,
} from '../../constants';

/**
 *quickSearchSelectLogOutのProps
 */
interface Props {
  /** 表示するアイコンタイプ */
  type: QuickSearchType;
  /** 親コンポーネントから渡されるアクティブ状態 */
  isActive: boolean;
  /** 親コンポーネントからのトグル関数 */
  onToggle: () => void;
  /** どのtypeとvalueが選択されたか判定する関数 */
  onSelectItem: (value: string, type: string) => void;
}

type Item =
  | (typeof OCCUPATIONS)[number]
  | (typeof LOCATIONS)[number]
  | (typeof INCOMES)[number];

export const QuickSearchSelectLogOut: FC<Props> = ({
  type,
  isActive,
  onToggle,
  onSelectItem,
}) => {
  const selectedData = QUICK_SEARCH_MAP[type];
  const [selectedItem, setSelectedItem] = useState<Item>(selectedData[0]);

  const handleSelect = (item: Item) => {
    setSelectedItem(item);
    onToggle();
    onSelectItem(item.value, type);
  };
  return (
    <>
      {isActive && (
        <button
          type="button"
          className={styles.quickSearchSelectLogOut__background}
          onClick={onToggle}
        />
      )}
      <div className={styles.quickSearchSelectLogOut__wrap}>
        <button
          className={classNames(
            styles.quickSearchSelectLogOut__container,
            styles[`quickSearchSelectLogOut__container--${type}`]
          )}
          onClick={onToggle}
          type="button"
        >
          <span
            className={classNames(
              styles.quickSearchSelectLogOut__icon,
              styles[`quickSearchSelectLogOut__icon--${type}`]
            )}
          />
          <span
            className={classNames(
              styles.quickSearchSelectLogOut__selectedText,
              selectedItem.name === selectedData[0].name
                ? styles[`quickSearchSelectLogOut__selectedText--notSelected`]
                : styles[`quickSearchSelectLogOut__selectedText--selected`]
            )}
          >
            {selectedItem.name}
          </span>
          <span className={styles.quickSearchSelectLogOut__arrowIcon}>
            <ArrowDown size="M" color="blue" />
          </span>
        </button>

        {isActive && (
          <div
            className={classNames(
              styles.quickSearchSelectLogOutDropdown,
              styles[`quickSearchSelectLogOutDropdown--${type}`]
            )}
            tabIndex={-1}
          >
            <ul role="listbox" tabIndex={-1}>
              {selectedData.map((item: Item) => (
                <li key={item.value}>
                  <button
                    type="button"
                    className={styles.quickSearchSelectLogOutDropdown__button}
                    onClick={() => handleSelect(item)}
                    onKeyDown={(event) => {
                      if (event.key === 'Enter') {
                        handleSelect(item);
                      }
                    }}
                  >
                    {item.name}
                  </button>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </>
  );
};
