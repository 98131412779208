import { useAppDispatch } from '@doda/common/src/redux/hooks';
import { useEffect } from 'react';
import { measureGoogleAnalyticsIdLastNumber } from '../../logics/analytics';

/**
 * Google Analytics IDの末尾計測
 * @param isLoggedIn ログイン状態
 * @param id google analytics id
 */
export const useMeasureGoogleAnalyticsId = (
  isLoggedIn: boolean,
  id: string
) => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (isLoggedIn) return;
    dispatch(measureGoogleAnalyticsIdLastNumber(id));
  }, [dispatch, isLoggedIn, id]);
};
