import { ComponentProps, FC } from 'react';
import { ArrowRight } from '@doda/io-react/common';
import styles from './index.module.scss';

/**
 * 一覧リンクコンポーネント（背景なし）のprops
 */
interface MoveToListLinkNoBackgroundProps {
  /** 一覧リンクのURL */
  href: string;
  /** 一覧リンクの表示テキスト */
  text: string;
  /** 一覧リンクのクリックイベントハンドラー */
  onClick?: ComponentProps<'a'>['onClick'];
}

export const MoveToListLinkNoBackground: FC<
  MoveToListLinkNoBackgroundProps
> = ({ href, text, onClick }) => {
  return (
    <a
      href={href}
      onClick={onClick}
      className={styles.moveToListLinkNoBackground__listLink}
    >
      {text}
      <ArrowRight color="blue" size="XS" />
    </a>
  );
};
