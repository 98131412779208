import { useEffect } from 'react';
import { useAppDispatch } from '@doda/common/src/redux/hooks';
import { measureChatClick } from '../../logics/analytics';

const selector = '.showtalk-guidance-option';

/**
 * クリックイベント付与
 * @param elements HTML要素リスト
 * @param handleClick クリック時に発火する関数
 */
const addClickListener = (
  elements: NodeListOf<Element>,
  handleClick: () => void
) => {
  elements.forEach((element) => {
    element.addEventListener('click', handleClick);
  });
};

/**
 * 「チャットで相談」ポップアップ内のボタンクリック時の計測発火
 */
export const useMeasureChatPopupClick = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    const handleClick = () => {
      dispatch(measureChatClick());
    };
    let chatPopupButtons: NodeListOf<Element> =
      document.querySelectorAll(selector);

    if (chatPopupButtons.length > 0) {
      addClickListener(chatPopupButtons, handleClick);
    } else {
      const mutationObserver = new MutationObserver(
        (mutationList, observer) => {
          mutationList.forEach((mutation) => {
            if (mutation.type !== 'childList') return;

            chatPopupButtons = document.querySelectorAll(selector);
            if (chatPopupButtons.length > 0) return;

            addClickListener(chatPopupButtons, handleClick);
            observer.disconnect();
          });
        }
      );

      mutationObserver.observe(document.body, {
        childList: true,
        subtree: true,
      });
      return () => {
        mutationObserver.disconnect();
      };
    }

    return () => {
      chatPopupButtons.forEach((button) => {
        button.removeEventListener('click', handleClick);
      });
    };
  }, [dispatch]);
};
