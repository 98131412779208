import { loginStatusSelector } from '@doda/common/src/redux/features/memberInfo';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

/**
 * チャットパーツの初期化およびイベントリスナーの設定を行うカスタムフック
 */
export const useChatParts = () => {
  const isLogin = useSelector(loginStatusSelector);
  useEffect(() => {
    window.dataLayer = window.dataLayer || [];

    const scrollEffect = () => {
      const scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      if (scrollTop > 500) {
        window.dataLayer.push({
          event: 'chatTag_default',
        });
        window.removeEventListener('scroll', scrollEffect);
      }
    };

    if (isLogin) {
      window.ShowTalkCustomerValue3 = 'login';
      window.addEventListener('scroll', scrollEffect);
    } else {
      window.ShowTalkCustomerValue3 = 'logout';
      window.dataLayer.push({
        event: 'chatTag_default',
      });
    }
    return () => {
      window.removeEventListener('scroll', scrollEffect);
    };
  }, [isLogin]);
};
