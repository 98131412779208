import { FC } from 'react';
import styles from './index.module.scss';
import { createOnAnchorClick } from '../../logics/createOnAnchorClick';

/**
 * 右サイドナビゲーション_上部リンクバナー情報
 */
export interface RightSideNavigationTopBanner {
  /** 画像URL */
  image: string;
  /** 画像が表示できないときの代わりに表示される文言 */
  alt: string;
  /** 遷移先のURL */
  destinationUrl: string;
  /** a タグの href 属性 */
  href: string;
  /** a タグの target 属性 */
  target?: '_blank';
  /** a タグの rel 属性 */
  rel?: 'noopener';
  /** 外部ウィンドウで開くかどうかの設定 */
  externalWindow: boolean;
}

/**
 * sideNavTopLinkImageBannerを配列にしたProps
 */
interface SideNavTopLinkBannerProps {
  sideNavTopLinkImageBannerList: RightSideNavigationTopBanner[];
}

export const SideNavTopLinkBanner: FC<SideNavTopLinkBannerProps> = ({
  sideNavTopLinkImageBannerList,
}) => {
  return (
    <>
      {sideNavTopLinkImageBannerList.map((banner) => {
        const onClick = createOnAnchorClick(
          banner.destinationUrl,
          banner.externalWindow
        );

        return (
          <a
            key={banner.href}
            className={styles.sideNavTopLinkBanner}
            href={banner.href}
            onClick={onClick}
            target={banner.target}
            rel={banner.rel}
          >
            <img
              className={styles.sideNavTopLinkBanner__image}
              src={banner.image}
              alt={banner.alt}
            />
          </a>
        );
      })}
    </>
  );
};
