import { ComponentProps, FC } from 'react';
import { ArrowRight } from '@doda/io-react/common';
import styles from './index.module.scss';

/**
 * 一覧リンクコンポーネントのprops
 */
interface MoveToListLinkProps {
  /** 一覧リンクのURL */
  href: string;
  /** 一覧リンクの表示テキスト */
  text: string;
  /** 一覧リンクのクリックイベントハンドラー */
  onClick?: ComponentProps<'a'>['onClick'];
}

export const MoveToListLink: FC<MoveToListLinkProps> = ({
  href,
  text,
  onClick,
}) => {
  return (
    <a
      href={href}
      onClick={onClick}
      className={styles.moveToListLink__listLink}
    >
      {text}
      <ArrowRight color="blue" size="S" />
    </a>
  );
};
