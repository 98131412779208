import { useAppDispatch } from '@doda/common/src/redux/hooks';
import { useEffect, useState } from 'react';
import { measureFreeWord } from '../../logics/analytics';

// 入力されたフリーワードが文字列がこの文字数になったとき、一度だけ計測を発火する
const TARGET_LENGTH = 3;

/**
 * トップ画面初期表示時のフリーワード計測hook。入力されたフリーワードが特定の文字数になったとき、一度だけ計測を発火する。
 * @param wordLength 入力されている文字数
 */
export const useMeasureFreeWord = (wordLength: number) => {
  const dispatch = useAppDispatch();
  const [hasBeenMeasured, setHasBeenMeasured] = useState(false);

  useEffect(() => {
    if (wordLength === TARGET_LENGTH && !hasBeenMeasured) {
      dispatch(measureFreeWord());
      setHasBeenMeasured(true);
    }
  }, [dispatch, wordLength, hasBeenMeasured, setHasBeenMeasured]);
};
