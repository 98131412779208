import { BasicModal, Button, Text } from '@doda/io-react/common';
import { FC, useState } from 'react';
import { PASSWORD_CHANGE_NOTIFICATION_MODAL_HREF } from './constants';
import styles from './index.module.scss';

/** パスワード変更通知モーダル */
export const PasswordChangeNotificationModal: FC = () => {
  const [showModal, setShowModal] = useState(true);

  const onClickClose = () => {
    setShowModal(false);
  };

  const handleClick = () => {
    setShowModal(false);
    window.location.href = PASSWORD_CHANGE_NOTIFICATION_MODAL_HREF;
  };

  if (!showModal) return null;

  return (
    <>
      <button
        className={styles.passwordChangeNotificationModal_background}
        type="button"
        onClick={onClickClose}
      />
      <BasicModal
        width="fit-content"
        onClickClose={onClickClose}
        show={showModal}
      >
        <div className={styles.passwordChangeNotificationModal}>
          <Text
            className={styles.passwordChangeNotificationModal__text}
            size="14--articlePC"
            color="black_pc"
          >
            dodaを安全にご利用いただけるよう <br />
            2023年3月14日よりパスワード設定時のルールを変更しています。
            <br />
            セキュリティ強化のため、パスワードを変更してください。
          </Text>
          <Button size="M" onClick={() => handleClick()} variant="green">
            変更する
          </Button>
        </div>
      </BasicModal>
    </>
  );
};
