import { FC } from 'react';
import { Box } from '@doda/io-react/common';
import styles from './index.module.scss';

/**
 * 緊急のお知らせコンポネントのprops
 */
interface UrgentMessageListProps {
  /** 緊急のお知らせリンク用配列 */
  urgentMessageLinkList: UrgentMessageLink[];
}

/**
 * 緊急のお知らせ情報
 */
export interface UrgentMessageLink {
  /** a タグの target 属性 */
  target?: '_blank';
  /** a タグの rel 属性 */
  rel?: 'noopener';
  /** 遷移先のurl */
  href: string;
  /** お知らせ内容 */
  text: string;
}

export const UrgentMessage: FC<UrgentMessageListProps> = ({
  urgentMessageLinkList,
}) => {
  if (!urgentMessageLinkList.length) return null;

  return (
    <Box
      color="white"
      border="gray"
      radius="4"
      className={styles.urgentMessage}
    >
      <ul className={styles.urgentMessage__list}>
        {urgentMessageLinkList.map((link) => (
          <li key={link.href}>
            <a
              href={link.href}
              target={link.target}
              rel={link.rel}
              className={styles.urgentMessage__anchorLink}
            >
              ・{link.text}
            </a>
          </li>
        ))}
      </ul>
    </Box>
  );
};
