import {
  RecommendSlider,
  RecommendSliderProps,
} from 'components/RecommendSlider';
import { formatString } from '@doda/common/src/utils/stringUtil';
import { RecommendPanelTop } from './types';
import {
  BOOK_MARK_URL,
  PERSONALIZED_RECOMMEND_LINK_URL,
  RECOMMEND_LINK_URL,
} from './constants';
import styles from './index.module.scss';

/**
 * レコメンドの取得データ
 * @property recommendJobList レコメンド求人リスト
 * @property handleClickThumbnail サムネイルクリック時に発火する関数
 * @property handleClickCardDetail カードタイトルクリック時に発火する関数
 * @property handleClickFavorite 気になるボタンクリック時に発火する関数
 * @property queryParamOnClick クリック時に付与するクエリパラメータ
 */
interface Props {
  recommendJobList: RecommendPanelTop;
  handleClickThumbnail: RecommendSliderProps['onClickThumbnail'];
  handleClickCardDetail: RecommendSliderProps['onClickCardDetail'];
  handleClickFavorite: RecommendSliderProps['onClickFavorite'];
  queryParamOnClick: string;
}

export const RecommendPanelLogIn = ({
  recommendJobList,
  handleClickThumbnail,
  handleClickCardDetail,
  handleClickFavorite,
  queryParamOnClick,
}: Props) => {
  const { recommendType, title, results, recommendLinkType, jobCount } =
    recommendJobList;
  const isNewArrival: boolean = recommendType === 'newjob_list';

  // NOTE: パーソナライズレコメンド以外は、1件以下の場合非表示とするため
  const shouldShowRecommends =
    recommendLinkType === 'personalized_area' || results.length > 1;

  if (!shouldShowRecommends) return null;

  const href =
    recommendLinkType === 'personalized_area'
      ? PERSONALIZED_RECOMMEND_LINK_URL[recommendType]
      : RECOMMEND_LINK_URL[recommendLinkType];

  return (
    <div className={styles.recommendPanelLogIn__slider}>
      <RecommendSlider
        recommendList={results}
        title={title}
        recommendType={recommendType}
        onClickSaved={undefined}
        bookmarkListUrl={formatString(BOOK_MARK_URL, ['login'])}
        saveBookmarkRequestBody={{ device: 'PC' }}
        isSummaryBottom
        href={href}
        onClickThumbnail={handleClickThumbnail}
        onClickCardDetail={handleClickCardDetail}
        onClickFavorite={handleClickFavorite}
        isNewArrival={isNewArrival}
        jobCount={jobCount}
        queryParamOnClick={queryParamOnClick}
      />
    </div>
  );
};
