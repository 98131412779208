import { appAxios } from '@doda/common/src/libs/axios/appAxios';
import { IncomingHttpHeaders } from 'http';
import { CmsMemberInfo } from '@doda/common/src/interfaces/api/cmsMemberInfo';

const KEYS =
  'referredJobCnt,saiyoprojectCnt,interviewOfferCnt,offerCnt,partnerAgentScoutCnt,desiredCondText,progressTopCnt,counselingButton,ngServiceIn,counselingReservedCheckButton';

/**
 * MemberInfoを取得する関数
 * @param headers - リクエストヘッダー
 * @returns 個人顧客情報
 */
export const getCmsMemberInfo = async (headers?: IncomingHttpHeaders) => {
  const response = await appAxios<CmsMemberInfo>({
    url: '/dcfront/cms/cmsMemberInfo/',
    headers,
    params: { keys: KEYS },
  });
  return response.data;
};
