import { FC } from 'react';
import styles from './index.module.scss';
import { SideNavMiddleLinkImageBanner } from './types';

/**
 *sideNavMiddleLinkImageBannerを配列にしたProps
 */
export interface SideNavMiddleLinkBannerProps {
  sideNavMiddleLinkImageBannerList: SideNavMiddleLinkImageBanner[];
}

export const SideNavMiddleLinkBanner: FC<SideNavMiddleLinkBannerProps> = ({
  sideNavMiddleLinkImageBannerList,
}) => {
  return (
    <div className={styles.sideNavMiddleLinkBanner}>
      {sideNavMiddleLinkImageBannerList.map((banner) => (
        <a
          key={banner.href}
          href={banner.href}
          target={banner.target}
          rel={banner.rel}
          className={styles.sideNavMiddleLinkBanner__link}
        >
          <img
            className={styles.sideNavMiddleLinkBanner__image}
            src={banner.image}
            alt={banner.alt}
          />
        </a>
      ))}
    </div>
  );
};
