import {
  MEASUREMENT_PARAMETERS_LOGOUT,
  MEASUREMENT_PARAMETERS_LOGIN,
} from '../../constants';

/**
 *計測用バラメータ作成ロジック
 * @param params クイックサーチの検索パラメータ
 * @param isLoggedIn
 * @returns 計測用パラメータ
 */
export const createMeasurementValue = (
  params: Map<string, string>,
  isLoggedIn: boolean
): string => {
  const measurementParam = isLoggedIn
    ? MEASUREMENT_PARAMETERS_LOGIN
    : MEASUREMENT_PARAMETERS_LOGOUT;

  const queries = Object.keys(measurementParam)
    .filter((key) => params.has(key))
    .map((key) => measurementParam[key])
    .join('-');

  return queries;
};
