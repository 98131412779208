import React, { FC } from 'react';
import styles from './index.module.scss';

/**
 *  職種リンクのProps
 */
interface Props {
  /** 職種名 */
  jobType: string;
  /** 職種に該当する求人数 */
  jobCount: number;
  /** 職種の子カテゴリ一覧 */
  subCategories: string;
}

export const ListItem: FC<Props> = ({ jobType, jobCount, subCategories }) => {
  return (
    <>
      <div className={styles.jobTypesSectionListItem__jobTypeTitle}>
        <span className={styles.jobTypesSectionListItem__jobName}>
          {jobType}
        </span>
        <span className={styles.jobTypesSectionListItem__jobCount}>
          ({jobCount.toLocaleString()})
        </span>
      </div>
      <div className={styles.jobTypesSectionListItem__jobTypeSubCategories}>
        {subCategories}
      </div>
    </>
  );
};
